import { cx } from "@emotion/css"
import { useNavigate } from "react-router-dom"
import SideBarMenu from "./side-bar-menu.component"
import Slot from "../assets/svg/slot.svg"
import Fishing from "../assets/svg/fishing.svg"
import Casino from "../assets/svg/casino.svg"
import Bookmark from "../assets/svg/bookmark.svg"
import Info from "../assets/svg/info.svg"
import { ROUTE } from "../common/constant/routes"
import { Fragment } from "react"
import Button from "../common/base-ui/button/button.component"
import Copyright from "../common/base-ui/copyright.component"
import Star from "../assets/svg/star.svg"
import Play from "../assets/svg/play.svg"
import SidebarMenuBG from "./side-bar-menu-bg.component"
import Line from "./line.component"
import Deposit from "../assets/svg/download.svg"
import Withdraw from "../assets/svg/upload.svg"
import Transaction from "../assets/svg/swap.svg"
import Affiliate from "../assets/svg/send.svg"
import Logout from "../assets/svg/lock.svg"
import { useContextAuthManager } from "../common/context/auth.context"
import useLogo from "../common/hooks/useLogo.hooks"
import { APP_NAME, LINE_OFFICIAL_LINK } from "../common/service/env"
import { gotoRedemption } from "../utils/outside-provider"
import { AGENT } from "../utils/cross-agent"

type UnauthorizedMenuProps = {
  isLoading: boolean
  lineLogin: () => void
}

const UnauthorizedMenu = ({ isLoading, lineLogin }: UnauthorizedMenuProps) => {
  const navigate = useNavigate()
  return (
    <Fragment>
      <SideBarMenu
        svg={Casino}
        onClick={() => window.open(`https://${APP_NAME}.com/เล่นฟรี/ทดลองเล่นคาสิโน-ฟรี/`, "_blank")}
        text="ทดลองเล่นคาสิโนฟรี"
        alt="try-casino-free"
      />
      <SideBarMenu
        onClick={() => window.open(`https://${APP_NAME}.com/ทดลองเล่นคาสิโน-สล๊อต-ยิ/ทดลองเล่นสล๊อต-ฟรี/`, "_blank")}
        svg={Slot}
        text="ทดลองเล่นสล๊อตฟรี"
        alt="try-slot-free"
      />
      <SideBarMenu
        onClick={() => window.open(`https://${APP_NAME}.com/เล่นฟรี/ทดลองเล่นเกมยิงปลา-ฟรี/`, "_blank")}
        svg={Fishing}
        text="ทดลองเล่นยิงปลาฟรี"
        alt="try-fishing-free"
      />
      <Line />
      <SideBarMenu onClick={() => navigate(ROUTE.PROMOTION)} svg={Bookmark} text="โปรโมชั่น" alt="promotion-sidebar" />
      <SideBarMenu onClick={() => navigate(ROUTE.HELP)} svg={Info} text="ช่วยเหลือ" alt="info-sidebar" />
      <Line />
      <div
        onClick={() => window.open(LINE_OFFICIAL_LINK, "_blank")}
        className="bg-primary-line py-[15px] px-[10px] rounded-[10px] cursor-pointer text-center mb-4">
        <img
          src={`https://cms.bethub.link/wp-content/uploads/2023/03/line-sticker-old.png`}
          className="hover:scale-110 ease-in-out duration-300 mb-[15px]"
          alt="line-area"
          max-width="140px"
        />
        <div className="font-semibold text-[15px]">
          LINE: @{APP_NAME.toUpperCase()}
          {APP_NAME === AGENT.shiba168 && <>V3</>}
        </div>
        <div className="font-light text-sm">ติดต่อสอบถาม</div>
      </div>
      <Line />
      <Button
        onClick={() => navigate(ROUTE.SIGN_IN)}
        className={cx(isLoading ? "bg-gray-500" : "bg-primary-yellow", "text-primary-black px-[15px] py-[6px] w-full rounded-[100px] text-sm mb-4")}
        type="button">
        เข้าสู่ระบบ / สมัครสมาชิก
      </Button>
      <Button
        onClick={() => lineLogin()}
        className={cx(isLoading ? "bg-gray-500" : "bg-primary-line", "text-primary-white px-[15px] py-[6px] rounded-[100px] text-sm mr-2 w-full mb-4")}
        type="button">
        เข้าสู่ระบบด้วย LINE
      </Button>
      <Copyright />
    </Fragment>
  )
}

type AuthorizedMenuProps = {
  logout: () => void
}

const AuthorizedMenu = ({ logout }: AuthorizedMenuProps) => {
  const navigate = useNavigate()
  const { favoriteCount, recentPlayCount } = useContextAuthManager()
  return (
    <Fragment>
      <SidebarMenuBG onClick={() => navigate(ROUTE.FAVORITE)} svg={Star} text="เกมโปรด" alt="favorite" className="mb-2" count={favoriteCount} />
      <SidebarMenuBG onClick={() => navigate(ROUTE.RECENT_PLAY)} svg={Play} text="เล่นล่าสุด" alt="favorite" className="mb-4" count={recentPlayCount} />
      <Line />
      <SideBarMenu onClick={() => navigate(ROUTE.DEPOSIT)} svg={Deposit} text="ฝากเงิน" alt="menu-deposit" />
      <SideBarMenu onClick={() => navigate(ROUTE.WITHDRAW)} svg={Withdraw} text="ถอนเงิน" alt="menu-withdraw" />
      <SideBarMenu onClick={() => navigate(ROUTE.HISTORY)} svg={Transaction} text="ประวัติธุรกรรม" alt="menu-transaction" />
      <SideBarMenu onClick={() => gotoRedemption()} svg={Affiliate} text="ลิงค์รับทรัพย์" alt="menu-affiliate" />
      <Line />
      <SideBarMenu onClick={() => navigate(ROUTE.PROMOTION)} svg={Bookmark} text="โปรโมชั่น" alt="menu-promotion" />
      <SideBarMenu onClick={() => navigate(ROUTE.HELP)} svg={Info} text="ช่วยเหลือ" alt="menu-info" />
      <SideBarMenu onClick={() => logout()} svg={Logout} text="ออกจากระบบ" alt="menu-logout" className="absolute bottom-0" />
    </Fragment>
  )
}

const Sidebar = () => {
  const navigate = useNavigate()
  const { isLogIn, logout, lineLogin, isLoading } = useContextAuthManager()
  const { desktopLogo } = useLogo()

  return (
    <div className="w-[220px] h-full fixed top-0 left-0 p-4 bg-black/[0.15] hidden lg:block">
      <div className="px-4 pb-4 cursor-pointer" onClick={() => navigate(ROUTE.DASHBOARD)}>
        <img src={desktopLogo} alt={`${APP_NAME}-logo-sidebar`} />
      </div>
      <hr className="border-t-[#FFFFFF] opacity-[0.15] mb-4" />
      {isLogIn ? <AuthorizedMenu logout={logout} /> : <UnauthorizedMenu isLoading={isLoading} lineLogin={lineLogin} />}
    </div>
  )
}

export default Sidebar
