type CardProps = {
  img: string;
  title: string;
  alt: string;
  onClick: () => void;
};
const Card = ({ img, title, alt, onClick }: CardProps) => {
  return (
    <div
      onClick={onClick}
      className="hover:scale-110 hover:ease-in-out hover:duration-[250ms] flex flex-col items-center justify-center cursor-pointer space-y-2"
    >
      <img
        src={img}
        alt={"promotion-card-" + alt}
        className="w-full object-cover rounded-md h-[145px]"
      />
      <h1 className="text-white text-sm font-semibold text-center">{title}</h1>
    </div>
  );
};
export default Card;
