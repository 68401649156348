import { API_URL } from "./../service/env";
import { map } from "rxjs";
import BaseRxios from "../service/rxios";
import { IGame } from "../types/game.type";
import { GAME_TYPE } from "../constant/game-variable";

class GameAPI {
  http: BaseRxios;

  constructor() {
    this.http = new BaseRxios({
      baseURL: API_URL,
    });
  }

  private getGameType(hot: number, freespin: number, _new: number) {
    if (hot === 1) {
      return GAME_TYPE.HOT;
    } else if (_new === 1) {
      return GAME_TYPE.NEW;
    } else if (freespin === 1) {
      return GAME_TYPE.FREE_SPIN;
    } else {
      return null;
    }
  }

  getGamesByKeyword(keyword: string) {
    return this.http.get(`/api/v2/games/list?search=${keyword}`).pipe(
      map(({ data: { lists } }: any) => {
        return lists.map((item: any) => {
          return {
            id: item._id,
            nameTh: item.nameTh,
            category: item.category,
            categoryName: item.categoryName,
            code: item.code,
            class: this.getGameType(item.hot, item.freespin, item.new),
            freespin: item.freespin === 1,
            hot: item.hot === 1,
            img: item.img,
            language: item.language,
            launching: item.launching,
            nameEn: item.nameEn,
            new: item.new === 1,
            type: item.type,
            favorite: item.favourite === 1,
          } as IGame;
        });
      })
    );
  }

  getSubGamesByCategory(categoryName: string) {
    return this.http
      .get(`/api/v2/games/list?type=slot&category_name=${categoryName}`)
      .pipe(
        map(({ data: { lists } }: any) => {
          return lists.map((item: any) => {
            return {
              id: item._id,
              nameTh: item.nameTh,
              category: item.category,
              categoryName: item.categoryName,
              code: item.code,
              class: this.getGameType(item.hot, item.freespin, item.new),
              freespin: item.freespin === 1,
              hot: item.hot === 1,
              img: item.img,
              language: item.language,
              launching: item.launching,
              nameEn: item.nameEn,
              new: item.new === 1,
              type: item.type,
              favorite: item.favourite === 1,
            } as IGame;
          });
        })
      );
  }

  getGamesByType(type: string) {
    let url = "";
    if (type === "hot") {
      url = `/api/v2/games/?hot=1`;
    } else if (type === "new") {
      url = `/api/v2/games/?new=1`;
    } else {
      url = `/api/v2/games/?type=${type}`;
    }
    return this.http.get(url).pipe(
      map(({ data: { categorys } }: any) => {
        return categorys.map((item: any) => {
          return {
            id: item._id,
            nameTh: item.nameTh,
            category: item.category,
            categoryName: item.categoryName,
            code: item.code,
            class: this.getGameType(item.hot, item.freespin, item.new),
            freespin: item.freespin === 1,
            hot: item.hot === 1,
            img: item.img,
            language: item.language,
            launching: item.launching,
            nameEn: item.nameEn,
            new: item.new === 1,
            type: item.type,
            favorite: item.favourite === 1,
          } as IGame;
        });
      })
    );
  }

  getGames(type: string, limit: number) {
    let url = "";
    if (limit > 0) {
      url = `/api/v2/games/list?type=${type}&limit=${limit}`;
    } else {
      url = `/api/v2/games/list?type=${type}`;
    }
    return this.http.get(url).pipe(
      map(({ data: { lists } }: any) => {
        return lists.map((item: any) => {
          return {
            id: item._id,
            nameTh: item.nameTh,
            category: item.category,
            categoryName: item.categoryName,
            code: item.code,
            class: this.getGameType(item.hot, item.freespin, item.new),
            freespin: item.freespin === 1,
            hot: item.hot === 1,
            img: item.img,
            language: item.language,
            launching: item.launching,
            nameEn: item.nameEn,
            new: item.new === 1,
            type: item.type,
            favorite: item.favourite === 1,
          } as IGame;
        });
      })
    );
  }

  getHotGames(limit: number) {
    return this.http.get(`/api/v2/games/list?limit=${limit}&hot=1`).pipe(
      map(({ data: { lists } }: any) => {
        return lists.map((item: any) => {
          return {
            id: item._id,
            nameTh: item.nameTh,
            category: item.category,
            categoryName: item.categoryName,
            code: item.code,
            class: this.getGameType(item.hot, item.freespin, item.new),
            freespin: item.freespin === 1,
            hot: item.hot === 1,
            img: item.img,
            language: item.language,
            launching: item.launching,
            nameEn: item.nameEn,
            new: item.new === 1,
            type: item.type,
            favorite: item.favourite === 1,
          } as IGame;
        });
      })
    );
  }

  getNewGames(limit: number) {
    return this.http.get(`/api/v2/games/list?limit=${limit}&new=1`).pipe(
      map(({ data: { lists } }: any) => {
        return lists.map((item: any) => {
          return {
            id: item._id,
            nameTh: item.nameTh,
            category: item.category,
            categoryName: item.categoryName,
            code: item.code,
            class: this.getGameType(item.hot, item.freespin, item.new),
            freespin: item.freespin === 1,
            hot: item.hot === 1,
            img: item.img,
            language: item.language,
            launching: item.launching,
            nameEn: item.nameEn,
            new: item.new === 1,
            type: item.type,
            favorite: item.favourite === 1,
          } as IGame;
        });
      })
    );
  }

  favoriteGame(gameId: string) {
    return this.http.post(`/api/v2/games/favorites?game_id=${gameId}`).pipe(
      map(({ data: { addFavourite } }: any) => {
        return {
          countFavorite: addFavourite.countFavourite,
          countRecent: addFavourite.countRecent,
        };
      })
    );
  }

  getFavoriteGames() {
    return this.http.get("/api/v2/games/favorites").pipe(
      map(({ data: { lists } }: any) => {
        return lists.map((item: any) => {
          return {
            id: item._id,
            nameTh: item.nameTh,
            category: item.category,
            categoryName: item.categoryName,
            code: item.code,
            class: this.getGameType(item.hot, item.freespin, item.new),
            freespin: item.freespin === 1,
            hot: item.hot === 1,
            img: item.img,
            language: item.language,
            launching: item.launching,
            nameEn: item.nameEn,
            new: item.new === 1,
            type: item.type,
            favorite: item.favourite === 1,
          } as IGame;
        });
      })
    );
  }

  getRecentGames() {
    return this.http.get("/api/v2/games/recent").pipe(
      map(({ data: { lists } }: any) => {
        return lists.map((item: any) => {
          return {
            id: item._id,
            nameTh: item.nameTh,
            category: item.category,
            categoryName: item.categoryName,
            code: item.code,
            class: this.getGameType(item.hot, item.freespin, item.new),
            freespin: item.freespin === 1,
            hot: item.hot === 1,
            img: item.img,
            language: item.language,
            launching: item.launching,
            nameEn: item.nameEn,
            new: item.new === 1,
            type: item.type,
            favorite: item.favourite === 1,
          } as IGame;
        });
      })
    );
  }
}

export default GameAPI;
