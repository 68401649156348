import { cx } from "@emotion/css";
import { Fragment } from "react";
import { useField } from "react-final-form";

type InputProps = {
  label?: string;
  name: string;
  type: "number" | "text" | "password";
  placeholder?: string;
  className?: string;
  required?: boolean;
  maxlength?: number;
  pattern?: string;
  minlength?: number;
  value?: string;
  readOnly?: boolean;
};

const Input = ({
  label,
  name,
  type,
  className,
  placeholder,
  required = false,
  maxlength,
  pattern,
  minlength,
  value,
  readOnly = false,
}: InputProps) => {
  const { input } = useField(name);
  return (
    <Fragment>
      {label && (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-semibold text-primary-whites"
        >
          {label}
        </label>
      )}
      <input
        {...input}
        id={name}
        pattern={pattern}
        type={type}
        className={cx("outline-none", className)}
        placeholder={placeholder}
        required={required}
        maxLength={maxlength}
        minLength={minlength}
        value={value}
        readOnly={readOnly}
      />
    </Fragment>
  );
};

export default Input;
