import { cx } from "@emotion/css";
import { useEffect } from "react";
import { dotty } from "../../common/constant/animate-css";
import { useContextAuthManager } from "../../common/context/auth.context";
import useLogo from "../../common/hooks/useLogo.hooks";
import { APP_NAME } from "../../common/service/env";

const RedirectSignUp = () => {
  const { lineLogin } = useContextAuthManager();
  const { desktopLogo } = useLogo();
  useEffect(() => {
    setTimeout(() => {
      lineLogin();
    }, 2000);
  }, [lineLogin]);

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="max-w-[420px] rounded-[17px] p-4 flex flex-col justify-center items-center">
        <div className="mb-5 max-w-[205px]">
          <img
            src={desktopLogo}
            alt={`${APP_NAME}-logo-redirect`}
            height="58px"
            width="auto"
          />
        </div>

        <div
          className={cx("text-lg text-center font-bold leading-7 mb-4", dotty)}
        >
          กำลังพาท่านเข้าสู่การสมัครสมาชิก
        </div>
      </div>
    </div>
  );
};
export default RedirectSignUp;
