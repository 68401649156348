import { map } from "rxjs"
import BaseRxios from "../service/rxios"
import dayjs from "dayjs"
import { ITopUpHistory } from "../types/topup.type"
import { API_URL, XBET_API } from "../service/env"
class TopUpAPI {
  http: BaseRxios
  agentHttp: BaseRxios

  constructor() {
    this.http = new BaseRxios({
      baseURL: XBET_API,
    })
    this.agentHttp = new BaseRxios({
      baseURL: API_URL,
    })
  }
  withdraw(amount: number) {
    return this.http.post("/api/v1/member/withdraw", { balance: amount }).pipe(
      map((response: any) => {
        return {
          code: response.code,
          message: response.message,
        }
      })
    )
  }

  withdrawV2(amount: number) {
    return this.agentHttp.post("/api/v2/users/withdraw", { amount }).pipe(
      map((response: any) => {
        return {
          code: response.code,
          message: response.message,
        }
      })
    )
  }

  history() {
    return this.http.post("/api/v1/member/data/dataHistory").pipe(
      map(({ result: { list } }: any) => {
        return list.map((item: any) => {
          return {
            amount: item.amount,
            description: item.note,
            status: item.status,
            time: dayjs(item.time * 1000).format("DD/MM/YYYY HH:mm"),
            type: item.type ?? "-",
          } as ITopUpHistory
        })
      })
    )
  }

  historyV2() {
    return this.agentHttp.get("/api/v2/users/transactions").pipe(
      map(({ transactions }: any) => {
        return transactions.data.map((item: any) => {
          return {
            amount: item.amont,
            description: item.note,
            status: item.status,
            time: dayjs(item.created_at).format("DD/MM/YYYY HH:mm"),
            type: item.type ?? "-",
          } as ITopUpHistory
        })
      })
    )
  }
}

export default TopUpAPI
