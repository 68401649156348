import { css, cx } from "@emotion/css";
import { useNavigate } from "react-router-dom";
import Carousel from "../../../common/base-ui/carousel.component";
import { Banner } from "../../../common/types/banner.type";
import { isOutsideLink } from "../../../utils/outside-provider";

type CarouselSectionProps = {
  bannerList: Banner[];
};

const CarouselSection = ({ bannerList }: CarouselSectionProps) => {
  const navigate = useNavigate();
  return (
    <Carousel
      className={cx(
        "w-full rounded-xl mb-4",
        css`
          .slick-list {
            border-radius: 10px;
          }
        `
      )}
    >
      {bannerList.map((banner) => (
        <img
          onClick={() => {
            if (banner.redirect) {
              if (isOutsideLink(banner.redirect)) {
                window.open(banner.redirect, "_blank");
              } else {
                navigate(banner.redirect);
              }
            }
          }}
          key={`banner-carousel-${banner.id}`}
          src={banner.imgUrl}
          alt={`banner-carousel-${banner.id}`}
          width="100%"
          className="max-h-[365px] h-full"
        />
      ))}
    </Carousel>
  );
};
export default CarouselSection;
