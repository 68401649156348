import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react"
import { GROUP_ID, LIVE_CHAT_LICENSE } from "../service/env"
import { useContextAuthManager } from "../context/auth.context"
import { useMemo } from "react"

const LiveChat = () => {
  const { isLogIn, username, isShowContactUs } = useContextAuthManager()
  function handleNewEvent(event: EventHandlerPayload<"onNewEvent">) {
    console.log("LiveChatWidget.onNewEvent", event)
  }

  const visibility = useMemo(() => {
    return isShowContactUs ? "maximized" : "hidden"
  }, [isShowContactUs])

  if (!isLogIn) {
    return null
  }

  return (
    <>
      <LiveChatWidget license={LIVE_CHAT_LICENSE} group={GROUP_ID} customerName={username} visibility={visibility} onNewEvent={handleNewEvent} />
    </>
  )
}

export default LiveChat
