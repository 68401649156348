import { map } from "rxjs"
import { getBankByBankId } from "../../utils/banks"
import { API_URL, GROUP_ID } from "../service/env"
import BaseRxios from "../service/rxios"
import { IBankAccount } from "../types/topup.type"
import { LoginProps, Register } from "../types/user.type"

class UserAPI {
  http: BaseRxios

  constructor() {
    this.http = new BaseRxios({
      baseURL: API_URL,
    })
  }

  login({ username, password }: LoginProps) {
    return this.http
      .post("/api/v2/users/login", {
        username,
        password,
        groupID: GROUP_ID,
      })
      .pipe(
        map((response: any) => {
          if (response.code !== 5000) {
            return {
              token: response.access_token,
              refreshToken: response.refresh_token,
              username: response.result.username,
            }
          } else {
            throw response
          }
        })
      )
  }

  affiliate() {
    return this.http.get("/api/v2/users/affiliate").pipe(
      map(({ result }: any) => {
        return {
          username: result.username,
          register: result.register,
          credit: result.credit,
          click: result.click,
          commission: {
            casino: result.commission.casino,
            slot: result.commission.slot,
            sport: result.commission.sport,
          },
        }
      })
    )
  }

  truewalletStatus() {
    return this.http.get("/api/v2/users/truewallet").pipe(
      map(({ status }: any) => {
        return status === 1
      })
    )
  }

  truewalletUrl() {
    return this.http.get("/api/v2/users/truewallet/url").pipe(
      map(({ data }: any) => {
        return data
      })
    )
  }

  credit() {
    return this.http.get("/api/v2/users/credit").pipe(
      map(({ result }: any) => {
        return {
          balance: result.balance,
          username: result.user,
        }
      })
    )
  }

  register(payload: Register) {
    return this.http.post("/api/v2/register", payload).pipe(
      map((response: any) => {
        return response
      })
    )
  }

  bankAccount() {
    return this.http.get("/api/v2/users/bank-accounts").pipe(
      map(({ result }: any) => {
        const userBank = getBankByBankId(result.user_bank_account.bank_id)
        const depositBank = getBankByBankId(result.deposit_bank_account.bank_id)
        return {
          userBank: {
            bank: userBank,
            number: result.user_bank_account.account_number,
            name: result.user_bank_account.firstname + " " + result.user_bank_account.lastname,
          } as IBankAccount,
          depositBank: {
            bank: depositBank,
            number: result.deposit_bank_account.acc_no,
            name: result.deposit_bank_account.name_bank,
          } as IBankAccount,
          promptPay: result.payments.map((payment: { _id: string; name_bank: string }) => {
            return {
              id: payment._id,
              bankName: payment.name_bank,
            }
          }),
        }
      })
    )
  }

  promptPay(id: string) {
    return this.http.get(`/api/v2/users/payment/${id}`).pipe(
      map(({ url }: any) => {
        return url
      })
    )
  }
}

export default UserAPI
