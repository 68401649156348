import { cx } from "@emotion/css";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import UserAPI from "../../common/api/user.api";
import Button from "../../common/base-ui/button/button.component";
import AlertModal from "../../common/base-ui/modal/alert-modal.component";
import { ROUTE } from "../../common/constant/routes";
import { useContextAuthManager } from "../../common/context/auth.context";
import useLogo from "../../common/hooks/useLogo.hooks";
import { APP_NAME } from "../../common/service/env";
import {
  getMigratePassword,
  getMigrateUsername,
  setRefreshToken,
  setToken,
} from "../../common/service/local-storage";
import { ErrorModal } from "../../common/types/modal.type";
import { LoginProps } from "../../common/types/user.type";

const SignUpComplete = () => {
  const navigate = useNavigate();
  const { desktopLogo } = useLogo();
  const { setIsLogIn, getUserData } = useContextAuthManager();

  const migrateUsername = getMigrateUsername() || "";
  const migratePassword = getMigratePassword() || "";
  const [isLoading, setLoading] = useState(false);

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({} as ErrorModal);

  const userRepository = useMemo(() => {
    return new UserAPI();
  }, []);

  useEffect(() => {
    if (!migrateUsername && !migratePassword) {
      return navigate(ROUTE.SIGN_IN);
    }
  }, [migrateUsername, migratePassword, navigate]);

  const onSubmit = async () => {
    const toastId = toast.loading("กำลังเข้าสู่ระบบ...");
    setLoading(true);
    const payload: LoginProps = {
      username: migrateUsername,
      password: migratePassword,
    };
    userRepository.login(payload).subscribe({
      next: (res) => {
        setToken(res.token);
        setRefreshToken(res.refreshToken);
        toast.dismiss(toastId);
        toast.success("เข้าสู่ระบบสำเร็จ");
        setIsLogIn(true);
        getUserData();
        setLoading(false);
        navigate(ROUTE.DASHBOARD);
      },
      error: (err) => {
        toast.dismiss(toastId);
        toast.error("เข้าสู่ระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
        console.error(err);
        setErrorMessage({
          message: err.message,
          code: err.code,
        });
        setIsAlertModalOpen(true);
        setLoading(false);
        navigate(ROUTE.SIGN_IN);
      },
    });
  };
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="max-w-[420px] rounded-[17px] p-4 md:bg-black/5 md:border md:border-white/[0.15] md:border-solid">
        <div className="mx-auto mb-4 max-w-[205px]">
          <img
            src={desktopLogo}
            alt={`${APP_NAME}-logo-signup-complete`}
            height="58px"
            width="auto"
          />
        </div>
        <div className="text-lg font-bold leading-7 mb-4 text-center">
          สมัครสมาชิกสำเร็จ
        </div>
        <div className="text-center">
          <div className="mb-2">ชื่อผู้ใช้งาน</div>
          <div className="mb-4 font-bold text-lg">{migrateUsername}</div>
          <div className="mb-2">รหัสผ่าน</div>
          <div className="mb-4 font-bold text-lg">{migratePassword}</div>
          <div className="mb-4">
            หากกลัวจำรหัสผ่านไม่ได้ สามารถแคปรูปหน้านี้ได้
            <br />
            เพื่อกลับไปดูในคลังโทรศัพท์ท่านได้
          </div>
        </div>
        <Button
          disabled={isLoading}
          className={cx(
            isLoading ? "bg-gray-500" : "bg-primary-yellow",
            "text-primary-black px-[15px] py-2 rounded-[100px] text-sm mr-2 w-full mb-4"
          )}
          onClick={onSubmit}
        >
          เข้าสู่ระบบ
        </Button>
      </div>
      <AlertModal
        isOpen={isAlertModalOpen}
        setIsOpen={setIsAlertModalOpen}
        error={errorMessage}
      />
    </div>
  );
};
export default SignUpComplete;
