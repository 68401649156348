import { cx } from "@emotion/css";
import { IMAGE_CDN_URL } from "../../constant/cdn";
import { GAME_TYPE } from "../../constant/game-variable";
import FavoriteButton from "./favorite-button.component";
import GameTag from "./game-tag.component";

type GameMDProps = {
  favorite?: () => void;
  name: string;
  categoryName: string;
  className?: string;
  imgUrl: string;
  onClick: () => void;
  type?: GAME_TYPE;
  isFavorite: boolean;
};

const GameMD = ({
  favorite,
  name,
  categoryName,
  className,
  imgUrl,
  onClick,
  type,
  isFavorite,
}: GameMDProps) => {
  return (
    <div className="relative w-full hover:scale-110 hover:ease-in-out hover:duration-[250ms]">
      {type && <GameTag type={type} />}
      {favorite && (
        <FavoriteButton isFavorite={isFavorite} onClick={favorite} />
      )}
      <div onClick={onClick} className="cursor-pointer">
        <div className="border border-solid border-primary-white/[0.15] rounded-xl overflow-hidden">
          <img
            className={cx("w-full object-cover", className)}
            alt={name}
            src={`${IMAGE_CDN_URL + imgUrl}`}
          />
        </div>
        <div className="mt-[10px] font-light text-xs leading-4 text-primary-white/50">
          {categoryName}
        </div>
        <div className="truncate w-[100px]">{name}</div>
      </div>
    </div>
  );
};
export default GameMD;
