import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import GameAPI from "../../common/api/game.api"
import GameLG from "../../common/base-ui/games/game-lg.component"
import GameMD from "../../common/base-ui/games/game-md.component"
import GameSM from "../../common/base-ui/games/game-sm.component"
import Title from "../../common/base-ui/games/title.component"
import { ROUTE } from "../../common/constant/routes"
import { useContextAuthManager } from "../../common/context/auth.context"
import { useGame } from "../../common/hooks/useGame.hooks"
import { IGame } from "../../common/types/game.type"
import CarouselSection from "./component/carousel-section.component"
import InfoSection from "./component/info-section.component"
import take from "lodash/take"
import { useMediaSize } from "../../common/hooks/useMedia.hook"
import { css, cx } from "@emotion/css"
import RedemptionAPI from "../../common/api/redemption.api"
import AnnouncementModal from "../../common/base-ui/modal/announcement-modal.component"
import { AnnounceModal, ErrorModal } from "../../common/types/modal.type"
import AnnouncementAPI from "../../common/api/announcement.api"
import { isTodayShowModal } from "../../utils/show-promotion-modal"
import BannerAPI from "../../common/api/banner.api"
import { IActivity } from "../../common/types/redemption"
import Activity from "../../common/base-ui/activity.component"
import AlertModal from "../../common/base-ui/modal/alert-modal.component"
import { getToken } from "../../common/service/local-storage"
import { APP_NAME } from "../../common/service/env"
import { Banner } from "../../common/types/banner.type"
import TryToPlay from "./component/try-to-play.component"
import ContactUsModal from "../../common/base-ui/modal/contact-us-modal.component"

const Dashboard = () => {
  const navigate = useNavigate()
  const { useDesktopMediaQuery, useTabletMediaQuery } = useMediaSize()
  const { isLogIn, balance, username } = useContextAuthManager()
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [openContactUsModal, setOpenContactUsModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState({} as ErrorModal)
  const { play } = useGame({ isLogIn, setIsAlertModalOpen, setErrorMessage })
  const [hotCasino, setHotCasino] = useState<IGame[]>([])
  const [hotGames, setHotGames] = useState<IGame[]>([])
  const [newGames, setNewGames] = useState<IGame[]>([])
  const [fishingGames, setFishingGames] = useState<IGame[]>([])
  const [isReady, setIsReady] = useState(false)

  const [isActivityOpen, setIsActivityOpen] = useState(true)
  const [activity, setActivity] = useState<IActivity>()

  const [missionNotifyCount, setMissionNotifyCount] = useState(0)

  const [isAnnounceModalOpen, setIsAnnounceModalOpen] = useState(false)
  const [announce, setAnnounce] = useState({} as AnnounceModal)

  const [bannerList, setBannerList] = useState<Banner[]>([])

  // Banner Carousel
  useEffect(() => {
    const banner = new BannerAPI()
    banner.getBanner().subscribe({
      next: (res) => {
        setBannerList(res)
      },
      error: (err) => {
        console.error(err)
      },
    })
  }, [])

  // Announcement Modal
  useEffect(() => {
    const isShowModalToday = isTodayShowModal()
    if (isShowModalToday && isLogIn) {
      const announce = new AnnouncementAPI()
      announce.getMainAnnounce().subscribe({
        next: (res) => {
          if (res.length > 0) {
            setAnnounce({
              image: res[0].image,
              redirect: res[0].redirect,
              title: res[0].title,
              message: res[0].message,
              buttonText: res[0].buttonText,
            })
            if (res[0].isVIP) {
              // Redemption check vip user
              const redemption = new RedemptionAPI()
              redemption.getIsVIPUser().subscribe({
                next: (response) => {
                  if (response.isVIP) {
                    setIsAnnounceModalOpen(true)
                  }
                },
                error: (err) => {
                  console.error(err)
                },
              })
            } else {
              setIsAnnounceModalOpen(true)
            }
          }
        },
        error: (err) => {
          console.error(err)
        },
      })
    }
  }, [isLogIn])

  // Redemption mission notify
  useEffect(() => {
    if (isLogIn) {
      const redemption = new RedemptionAPI()
      redemption.getMissionNotify().subscribe({
        next: (res) => {
          setMissionNotifyCount(res.count)
        },
        error: (err) => {
          console.error(err)
        },
      })
    }
  }, [isLogIn])

  // Redemption activity
  useEffect(() => {
    const redemption = new RedemptionAPI()
    redemption.getActivityV2().subscribe({
      next: (res) => {
        const token = getToken()
        if (res.isActive === true) {
          setActivity({
            isActive: res.isActive,
            image: res.image,
            redirect: res.redirect.replace("{token}", token).replace("{url}", `https://app.${APP_NAME}.com/`),
          })
        }
      },
      error: (err) => {
        console.error(err)
      },
    })
  }, [])

  const gameRepository = useMemo(() => {
    return new GameAPI()
  }, [])

  useEffect(() => {
    const subscribe = gameRepository.getGames("casino", 6).subscribe({
      next: (res) => {
        setHotCasino(res)
      },
      error: (err) => {
        console.error(err)
      },
      complete: () => {
        setIsReady(true)
      },
    })
    return () => {
      subscribe.unsubscribe()
    }
  }, [gameRepository])

  useEffect(() => {
    const subscribe = gameRepository.getHotGames(9).subscribe({
      next: (res) => {
        setHotGames(res)
      },
      error: (err) => {
        console.error(err)
      },
    })
    return () => {
      subscribe.unsubscribe()
    }
  }, [gameRepository])

  useEffect(() => {
    const subscribe = gameRepository.getNewGames(8).subscribe({
      next: (res) => {
        if (!useDesktopMediaQuery) {
          setNewGames(take(res, 6))
        } else {
          setNewGames(res)
        }
      },
      error: (err) => {
        console.error(err)
      },
    })
    return () => {
      subscribe.unsubscribe()
    }
  }, [gameRepository, useDesktopMediaQuery])

  useEffect(() => {
    const subscribe = gameRepository.getGames("fishing", 9).subscribe({
      next: (res) => {
        setFishingGames(res)
      },
      error: (err) => {
        console.error(err)
      },
      complete: () => {
        setIsReady(true)
      },
    })
    return () => {
      subscribe.unsubscribe()
    }
  }, [gameRepository])

  return (
    <div className="w-full">
      {isLogIn && <InfoSection balance={balance} username={username} missionNotifyCount={missionNotifyCount} setOpenContactUsModal={setOpenContactUsModal} />}
      {bannerList.length > 0 && <CarouselSection bannerList={bannerList} />}
      {!isLogIn && <TryToPlay />}
      {isReady && (
        <Title onClick={() => navigate(ROUTE.GAMES + "?type=casino")} title="คาสิโนที่ยอดนิยมเป็นพิเศษ">
          <div className="flex 2xl:justify-center overflow-x-auto hide-scroll-bar w-full gap-3">
            {hotCasino.map((casino, index) => {
              return <GameLG onClick={() => play(casino)} key={"casino-" + index} name={casino.nameEn} categoryName={casino.categoryName} imgUrl={casino.img} />
            })}
          </div>
        </Title>
      )}
      <Title onClick={() => navigate(ROUTE.GAMES + "?type=hot")} title="ลองดูเกมยอดฮิตหน่อยเป็นไง">
        <div
          className={cx(
            "grid grid-flow-col overflow-x-auto hide-scroll-bar gap-3",
            css`
              grid-template-rows: 1fr 1fr 1fr;
              ${!useTabletMediaQuery ? "grid-template-columns: repeat(3, minmax(285px, 1fr));" : ""}
            `
          )}>
          {hotGames.map((game, index) => {
            return <GameSM onClick={() => play(game)} key={"hot-game-" + index} name={game.nameEn} categoryName={game.categoryName} imgUrl={game.img} isHover />
          })}
        </div>
      </Title>
      <Title onClick={() => navigate(ROUTE.GAMES + "?type=new")} title="ลองดูเกมใหม่ที่น่าสนใจ">
        <div className="grid grid-flow-col overflow-x-auto hide-scroll-bar gap-3">
          {newGames.map((game, index) => {
            return (
              <GameMD
                isFavorite={game.favorite}
                onClick={() => play(game)}
                key={"new-game-" + index}
                name={game.nameEn}
                categoryName={game.categoryName}
                imgUrl={game.img}
              />
            )
          })}
        </div>
      </Title>
      <Title onClick={() => navigate(ROUTE.GAMES + "?type=fishing")} title="ชอบเกมยิงปลารึเปล่า ลองนี่สิ">
        <div
          className={cx(
            "grid grid-flow-col overflow-x-auto hide-scroll-bar gap-3",
            css`
              grid-template-rows: 1fr 1fr 1fr;
              ${!useTabletMediaQuery ? "grid-template-columns: repeat(3, minmax(285px, 1fr));" : ""}
            `
          )}>
          {fishingGames.map((fishing, index) => {
            return (
              <GameSM
                onClick={() => play(fishing)}
                key={"fishing-game-" + index}
                name={fishing.nameEn}
                categoryName={fishing.categoryName}
                imgUrl={fishing.img}
                isHover
              />
            )
          })}
        </div>
      </Title>
      <AnnouncementModal isOpen={isAnnounceModalOpen} setIsOpen={setIsAnnounceModalOpen} announce={announce} />
      <AlertModal isOpen={isAlertModalOpen} setIsOpen={setIsAlertModalOpen} error={errorMessage} />
      <ContactUsModal isOpen={openContactUsModal} setIsOpen={setOpenContactUsModal} />
      {activity && activity.isActive && isActivityOpen && <Activity image={activity.image} url={activity.redirect} onClick={() => setIsActivityOpen(false)} />}
    </div>
  )
}
export default Dashboard
