import { getToken } from "../common/service/local-storage";

export const isOutsideLink = (redirectLink: string) => {
  if (redirectLink.includes("https:")) {
    return true;
  } else {
    return false;
  }
};

export const gotoRedemption = () => {
  return window.open(
    `https://redemption.bethub.link/?code=${getToken()}&url=${
      window.location.origin
    }`,
    "_blank"
  );
};
