import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import { ReactNode } from "react";

type DisclosureComponentProps = {
  title: string;
  children: ReactNode;
};

const DisclosureComponent = ({ title, children }: DisclosureComponentProps) => {
  return (
    <div className="text-sm w-full">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="mt-2.5 py-2.5 px-5 bg-black/25 rounded-md cursor-pointer flex items-center justify-between w-full">
              <span>{title}</span>
              <FontAwesomeIcon
                className={`${open ? "rotate-180 transform" : ""} h-4 w-4 `}
                icon={faChevronUp}
              />
            </Disclosure.Button>
            <Transition
              enter="transition transform duration-100 ease-in-out"
              enterFrom="transform translate-y-[-10px]"
              enterTo="transform translate-y-0"
              leave="transition transform duration-50 ease-in-out"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
            >
              <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                {children}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};
export default DisclosureComponent;
