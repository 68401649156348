import { cx } from "@emotion/css";

type LineProps = {
  className?: string;
};
const Line = ({ className }: LineProps) => {
  return (
    <hr className={cx("border-t-[#FFFFFF] opacity-[0.15] mb-4", className)} />
  );
};
export default Line;
