type DetailListProps = {
  title: string;
  amount: string;
};
const DetailList = ({ title, amount }: DetailListProps) => {
  return (
    <div className="flex justify-between text-sm px-2">
      <p className="text-primary-gray">{title}</p>
      <p className="text-white">{amount}</p>
    </div>
  );
};
export default DetailList;
