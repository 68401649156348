import { useEffect, useMemo, useState } from "react"
import TopUpAPI from "../../common/api/top-up.api"
import Title from "../../common/base-ui/title.component"
import TopUpIcon, { ICON_NAME } from "../../common/base-ui/icon/topup-icon.component"
import { cx } from "@emotion/css"
import { ITopUpHistory } from "../../common/types/topup.type"
import Clock from "../../assets/svg/clock.svg"
import Line from "../../layout/line.component"
import { ROUTE } from "../../common/constant/routes"
import { useNavigate } from "react-router-dom"

const History = () => {
  const navigate = useNavigate()
  const topUpXBetRepository = useMemo(() => {
    return new TopUpAPI()
  }, [])

  const [topUpHistory, setTopUpHistory] = useState<ITopUpHistory[]>([])

  useEffect(() => {
    topUpXBetRepository.historyV2().subscribe({
      next: (res) => {
        console.log("🚀 ~ res:", res)
        setTopUpHistory(res)
      },
      error: (err) => {
        console.error(err)
        if (err.code === 401) {
          navigate(ROUTE.DASHBOARD)
        }
      },
    })
  }, [topUpXBetRepository, navigate])

  const typeTh = (type: string) => {
    if (type === "deposit") {
      return "ฝากเงิน"
    } else if (type === "withdraw") {
      return "ถอนเงิน"
    } else if (type === "cancel") {
      return "ยกเลิก"
    } else {
      return "ติดต่อพนักงาน"
    }
  }

  const statusTh = ({ type, status }: ITopUpHistory) => {
    if (status === 0) {
      return "กำลังดำเนินการ"
    } else if (status === 1 && type === ICON_NAME.cancel) {
      return "ไม่สำเร็จ"
    } else {
      return "สำเร็จ"
    }
  }

  const amountTh = ({ type, amount }: ITopUpHistory) => {
    const amountFixed = amount.toFixed(2)
    if (type === ICON_NAME.deposit) {
      return `+ ${amountFixed}`
    } else if (type === ICON_NAME.withdraw) {
      return `- ${amountFixed}`
    } else {
      return `${amountFixed}`
    }
  }

  const bgColor = (type: ICON_NAME) => {
    return type === ICON_NAME.deposit ? "bg-primary-success" : "bg-primary-error"
  }

  const statusColorText = ({ type, status }: ITopUpHistory) => {
    if (status === 0) {
      return "text-primary-error"
    } else if (status === 1 && type === ICON_NAME.cancel) {
      return "text-primary-error"
    } else {
      return "text-primary-success"
    }
  }

  const amountColorText = ({ type, status }: ITopUpHistory) => {
    if (status === 0) {
      return "text-primary-error"
    } else if (status === 1 && type === ICON_NAME.cancel) {
      return "text-primary-white"
    } else {
      return "text-primary-success"
    }
  }

  return (
    <>
      <Title titleEn="Transaction History" titleTh="ประวัติการทำธุรกรรม" hasTab />
      {topUpHistory.map((item, index) => {
        return (
          <div key={`history-${index}`} className="w-full lg:max-w-[520px]">
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center">
                <div className={cx("rounded-3xl p-1 mr-2 flex items-center justify-center w-[40px] h-[40px]", bgColor(item.type))}>
                  <TopUpIcon name={item.type} />
                </div>
                <div className="flex justify-center flex-col">
                  <div className="flex items-center">
                    <div className="mr-2">{typeTh(item.type)}</div>
                    {item.description && <div>({item.description})</div>}
                  </div>
                  <div className="flex items-center">
                    <img className="mr-1" src={Clock} alt="clock" height="17px" width="17px" />
                    <div>{item.time}</div>
                  </div>
                </div>
              </div>
              <div className="text-right font-normal text-sm">
                <div className={amountColorText(item)}>{amountTh(item)} บาท</div>
                <div className={statusColorText(item)}>{statusTh(item)}</div>
              </div>
            </div>
            <Line />
          </div>
        )
      })}
    </>
  )
}
export default History
