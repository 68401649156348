import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import GameType from "../../common/base-ui/games/game-type.component";
import Line from "../../layout/line.component";
import GameAPI from "../../common/api/game.api";
import { IGame, IGameTypes } from "../../common/types/game.type";
import GameRow from "../../common/base-ui/games/game-row.component";
import GameMD from "../../common/base-ui/games/game-md.component";
import { useContextAuthManager } from "../../common/context/auth.context";
import { useGame } from "../../common/hooks/useGame.hooks";
import useQuery from "../../common/hooks/useQuery.hooks";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ROUTE } from "../../common/constant/routes";
import { GAME_TYPE } from "../../common/constant/game-variable";
import { TYPE } from "../../common/constant/query-url";
import { types } from "../../common/constant/game-types";
import { useMediaSize } from "../../common/hooks/useMedia.hook";
import Menu from "../dashboard/component/menu.component";
import GameSM from "../../common/base-ui/games/game-sm.component";
import AlertModal from "../../common/base-ui/modal/alert-modal.component";
import { ErrorModal } from "../../common/types/modal.type";

const Games = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const { useMobileMediaQuery } = useMediaSize();
  const { isLogIn, setFavoriteCount, favoriteCount, setRecentPlayCount } =
    useContextAuthManager();
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({} as ErrorModal);
  const { play, favorite } = useGame({
    isLogIn,
    setFavoriteCount,
    favoriteCount,
    setRecentPlayCount,
    setIsAlertModalOpen,
    setErrorMessage,
  });
  const [select, setSelect] = useState(
    types.find((type) => type.name === query.get(TYPE)) || types[0]
  );
  const [games, setGames] = useState<IGame[]>([]);
  const gameAPI = useMemo(() => {
    return new GameAPI();
  }, []);

  useEffect(() => {
    const subscribe = gameAPI.getGamesByType(select.name).subscribe({
      next: (res) => {
        setGames(res);
      },
      error: (err) => {
        console.error(err);
      },
    });
    return () => {
      subscribe.unsubscribe();
    };
  }, [gameAPI, select]);

  const navigateType = useCallback(
    (type: IGameTypes) => {
      setSelect(type);
      navigate(
        `${ROUTE.GAMES}?${createSearchParams({
          type: type.name,
        })}`
      );
    },
    [navigate]
  );

  return (
    <div className="w-full">
      <div className="flex md:gap-3 mb-4 justify-around md:justify-start">
        {types.map((type) => {
          if (type.name === GAME_TYPE.HOT || type.name === GAME_TYPE.NEW) {
            return null;
          }
          if (useMobileMediaQuery) {
            return (
              <GameType
                key={`game-menu-${type.alt}`}
                onClick={() => navigateType(type)}
                className={
                  select.name === type.name
                    ? "border-[#ffc10760]"
                    : "border-primary-white/5"
                }
                src={type.src}
                title={type.title}
                alt={type.alt}
              />
            );
          } else {
            return (
              <Menu
                onClick={() => navigateType(type)}
                key={`game-menu-small-${type.alt}`}
                src={type.src}
                alt={type.alt}
                text={type.title}
              />
            );
          }
        })}
      </div>
      <Line />
      <div className="font-bold text-lg leading-7 mb-4">
        {"รายการ" + select.title}
      </div>
      <GameRow>
        {games.map((game) => {
          if (useMobileMediaQuery) {
            return (
              <GameMD
                key={`game-md-${game.id}`}
                isFavorite={game.favorite}
                name={game.nameEn}
                categoryName={game.categoryName}
                favorite={() => favorite(game.id)}
                onClick={() => play(game)}
                className="min-h-[150px]"
                imgUrl={game.img}
              />
            );
          } else {
            return (
              <Fragment key={`game-fragment-${game.id}`}>
                <GameSM
                  onClick={() => play(game)}
                  key={`game-sm-${game.id}`}
                  name={game.nameEn}
                  categoryName={game.categoryName}
                  imgUrl={game.img}
                  isHover
                />
                <Line key={`game-line-${game.id}`} className="mb-0 ml-[90px]" />
              </Fragment>
            );
          }
        })}
      </GameRow>
      <AlertModal
        isOpen={isAlertModalOpen}
        setIsOpen={setIsAlertModalOpen}
        error={errorMessage}
      />
    </div>
  );
};
export default Games;
