// ! This file is not used in the project, it is old register for now we don't use it
/* eslint-disable */
import { cx } from "@emotion/css"
import { useMemo, useState } from "react"
import { Form } from "react-final-form"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import UserAPI from "../../common/api/user.api"
import Button from "../../common/base-ui/button/button.component"
import Input from "../../common/base-ui/fields/input"
import SelectBank from "../../common/base-ui/fields/select-bank"
import { useContextAuthManager } from "../../common/context/auth.context"
import useLogo from "../../common/hooks/useLogo.hooks"
import { APP_NAME } from "../../common/service/env"
import { AGENT } from "../../utils/cross-agent"

const SignUp = () => {
  const appNameCondition = APP_NAME === AGENT.shiba168 ? "168shiba" : APP_NAME
  const { affId, introduceId } = useContextAuthManager()
  const { desktopLogo } = useLogo()

  const userAPI = useMemo(() => {
    return new UserAPI()
  }, [])

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const onSubmit = async (values: any) => {
    const toastId = toast.loading("ระบบกำลังดำเนินการ...")
    setError("")
    if (values.bank === "0" || !values.bank) {
      return setError("กรุณาเลือกธนาคาร")
    }
    const payload = {
      bank_id: values.bankId,
      bank_account: values.bankAccountNumber.replace(/\D/g, ""),
      affiliate: affId,
      introduce_type: introduceId,
    }
    setTimeout(() => {
      toast.dismiss(toastId)
      toast.success("สมัครสมาชิกสำเร็จ")
    }, 2000)
  }
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="max-w-[420px] rounded-[17px] p-4 md:bg-black/5 md:border md:border-white/[0.15] md:border-solid">
        <div className="mb-4 max-w-[205px]">
          <img src={desktopLogo} alt={`${APP_NAME}-logo-login`} height="58px" width="auto" />
        </div>
        <div>REGISTER</div>
        <div className="text-lg font-bold leading-7 mb-4">สมัครสมาชิก</div>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <SelectBank name="bank" />
                <div>
                  <Input
                    label="หมายเลขบัญชี"
                    pattern="\d*"
                    type="text"
                    name="bankAccountNumber"
                    placeholder="กรอกหมายเลขบัญชี"
                    className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                    required
                  />
                </div>
                <div className="text-center text-primary-red mb-4">{error}</div>
                <Button
                  disabled={isLoading}
                  className={cx(isLoading ? "bg-gray-500" : "bg-primary-yellow", "text-primary-black px-[15px] py-2 rounded-[100px] text-sm mr-2 w-full mb-4")}
                  type="submit">
                  สมัครสมาชิก
                </Button>
                <div className="text-center text-sm font-normal leading-[21px] break-words">
                  {appNameCondition}.com ผู้ให้บริการคาสิโนออนไลน์อย่างเป็นทางการ สงวนลิขสิทธ์ © 2021, {appNameCondition}.com
                </div>
              </form>
            )
          }}
        />
      </div>
    </div>
  )
}
export default SignUp
