type MenuProps = {
  src: string;
  alt: string;
  text: string;
  isLine?: boolean;
  missionNotifyCount?: number;
  onClick: () => void;
};

const Menu = ({
  src,
  alt,
  text,
  isLine = false,
  missionNotifyCount = 0,
  onClick,
}: MenuProps) => {
  return (
    <div
      className="flex flex-col items-center cursor-pointer relative min-w-[75px]"
      onClick={onClick}
    >
      {missionNotifyCount > 0 && (
        <div className="flex absolute -top-2 right-0 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-gray-900">
          {missionNotifyCount}
        </div>
      )}
      {isLine ? (
        <div className="p-2 rounded-xl bg-primary-line">
          <img src={src} alt={alt} height="40px" width="40px" />
        </div>
      ) : (
        <div className="rounded-xl border-solid border p-2 border-white/10">
          <img src={src} alt={alt} height="40px" width="40px" />
        </div>
      )}
      <div className="mt-2">{text}</div>
    </div>
  );
};
export default Menu;
