import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type DescriptionProps = {
  customerBankName?: string;
  customerBankAccountNumber?: string;
};

const Description = ({
  customerBankName,
  customerBankAccountNumber,
}: DescriptionProps) => {
  return (
    <div className="w-full lg:max-w-[520px] flex border border-solid border-primary-white/5 rounded-2xl px-4 py-3 mb-4">
      <FontAwesomeIcon className="mr-4 cursor-pointer" icon={faBookmark} />
      <div>
        <div className="text-[13px] lg:text-sm font-normal mb-2">
          ใช้บัญชี{" "}
          <span className="text-primary-yellow !font-bold !text-sm leading-5 mx-1">
            {customerBankName}
          </span>{" "}
          เลขที่บัญชี{" "}
          <span className="text-primary-yellow !font-bold !text-sm leading-5 mx-1">
            {customerBankAccountNumber}
          </span>{" "}
          โอนเท่านั้น!
        </div>
        <div className="text-xs font-normal text-primary-white/50">
          เครดิตจะเข้าอัตโนมัติ ภายใน 5 นาที โดยไม่ต้องแจ้งสลิปค่ะ
        </div>
      </div>
    </div>
  );
};
export default Description;
