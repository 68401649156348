export const enum ROUTE {
  DASHBOARD = "/",
  SIGN_IN_WITH_REDIRECT = "/signin/:redirect",
  SIGN_IN = "/signin",
  SIGN_UP = "/signup",
  REGISTER = "/register",
  LINE_MIGRATE = "/line/migrate",
  USER_CONNECT = "/user/connect",
  SIGN_UP_COMPLETE = "/signup/complete",
  PROMOTION = "/promotion",
  PROMOTION_DETAIL = "/promotion/:id",
  HELP = "/help",
  FAVORITE = "/favorite",
  RECENT_PLAY = "/recent-play",
  DEPOSIT = "/deposit",
  WITHDRAW = "/withdraw",
  HISTORY = "/history",
  AFFILIATE = "/affiliate",
  AFFILIATE_FRIENDS = "/affiliate/friends",
  AFFILIATE_HISTORY = "/affiliate/history",
  SEARCH = "/search",
  GAMES = "/games",
  SUB_GAMES = "/sub-games",
  REDIRECT = "/redirect/:where",
}
