import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PromotionAPI from "../../common/api/promotion.api";
import { ROUTE } from "../../common/constant/routes";
import { useContextAuthManager } from "../../common/context/auth.context";
import { TPromotionDetail } from "../../common/types/promotion.type";
import DOMPurify from "dompurify";
// eslint-disable-next-line
import { APP_NAME } from "../../common/service/env";

const PromotionDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setIsLoading } = useContextAuthManager();
  const [promotion, setPromotion] = useState<TPromotionDetail>();
  useEffect(() => {
    if (id) {
      const promotion = new PromotionAPI();
      setIsLoading(true);
      promotion.getPromotionDetail(id).subscribe({
        next: (res) => {
          if (res) {
            setPromotion(res);
          } else {
            navigate(ROUTE.PROMOTION);
          }
        },
        error: (err) => {
          console.error(err);
        },
        complete: () => {
          setIsLoading(false);
        },
      });
    } else {
      navigate(ROUTE.PROMOTION);
    }
  }, [id, navigate, setIsLoading]);
  return (
    <>
      <div className="text-primary-yellow font-bold text-[28px] my-7">
        {promotion?.title}
      </div>
      {promotion?.imgUrl && (
        <img
          src={promotion?.imgUrl}
          alt={`promotion-detail-${id}`}
          className="max-h-[600px]"
        />
      )}
      {/* prose */}
      <div
        className="mt-5 mb-7 prose"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(promotion?.content || ""),
        }}
      ></div>
    </>
  );
};
export default PromotionDetail;
