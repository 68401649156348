import { css, cx } from "@emotion/css";

type LoadingSpinnerProps = {
  className?: string;
  width?: string;
  height?: string;
};

const LoadingSpinner = ({ className, width, height }: LoadingSpinnerProps) => {
  return (
    <div className="fixed h-full w-full top-0 left-0 right-0 bottom-0 bg-black/50">
      <div className="min-h-screen w-full flex lg:block justify-center items-center">
        <div className="lg:absolute -translate-y-1/2 top-1/2 left-[calc(50%+85px)]">
          <div
            className={cx(
              className,
              css`
                .lds-ring {
                  display: flex;
                  position: relative;
                  width: ${width || "40px"};
                  height: ${height || "40px"};
                }
                .lds-ring div {
                  box-sizing: border-box;
                  display: block;
                  position: absolute;
                  width: ${width || "40px"};
                  height: ${height || "40px"};
                  border: 4px solid #fff;
                  border-radius: 50%;
                  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                  border-color: #94a3b8 transparent transparent transparent;
                }
                .lds-ring div:nth-child(1) {
                  animation-delay: -0.45s;
                }
                .lds-ring div:nth-child(2) {
                  animation-delay: -0.3s;
                }
                .lds-ring div:nth-child(3) {
                  animation-delay: -0.15s;
                }
                @keyframes lds-ring {
                  0% {
                    transform: rotate(0deg);
                  }
                  100% {
                    transform: rotate(360deg);
                  }
                }
              `
            )}
          >
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoadingSpinner;
