import Button from "../button/button.component";
import Modal from "./modal.component";
import Warning from "../../../assets/images/warning.png";
import { ErrorModal } from "../../types/modal.type";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../constant/routes";

type AlertModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  error: ErrorModal;
};

const AlertModal = ({ isOpen, setIsOpen, error }: AlertModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal
      className="flex flex-col items-center justify-center p-12"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <img src={Warning} alt="alert-icon" width={150} height={150} />
      <div className="my-6 text-3xl font-medium leading-6 text-primary-red">
        แจ้งเตือน!
      </div>
      <div className="text-primary-black/25">{error.code}</div>
      <div className="mb-10 text-base text-center">{error.message}</div>
      {error.gotoLogin ? (
        <Button
          onClick={() => {
            navigate(ROUTE.SIGN_IN);
            setIsOpen(false);
          }}
          className="bg-gray-500 text-primary-white px-[15px] py-2 rounded-[100px] text-sm w-full focus:outline-none"
        >
          กลับสู่หน้าล็อคอิน
        </Button>
      ) : (
        <Button
          onClick={() => setIsOpen(false)}
          className="bg-gray-500 text-primary-white px-[15px] py-2 rounded-[100px] text-sm w-full focus:outline-none"
        >
          ปิดหน้าต่าง
        </Button>
      )}
    </Modal>
  );
};
export default AlertModal;
