import { useState, useMemo, useEffect, useCallback, Fragment } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import GameAPI from "../../common/api/game.api";
import GameMD from "../../common/base-ui/games/game-md.component";
import GameRow from "../../common/base-ui/games/game-row.component";
import GameType from "../../common/base-ui/games/game-type.component";
import { ROUTE } from "../../common/constant/routes";
import { useContextAuthManager } from "../../common/context/auth.context";
import { useGame } from "../../common/hooks/useGame.hooks";
import useQuery from "../../common/hooks/useQuery.hooks";
import { IGame, IGameTypes } from "../../common/types/game.type";
import Line from "../../layout/line.component";
import { Subscription } from "rxjs";
import { GAME_TYPE } from "../../common/constant/game-variable";
import { CATEGORY } from "../../common/constant/query-url";
import { types } from "../../common/constant/game-types";
import { useMediaSize } from "../../common/hooks/useMedia.hook";
import Menu from "../dashboard/component/menu.component";
import GameSM from "../../common/base-ui/games/game-sm.component";
import AlertModal from "../../common/base-ui/modal/alert-modal.component";
import { ErrorModal } from "../../common/types/modal.type";

const SubGames = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const { useMobileMediaQuery } = useMediaSize();
  const { isLogIn, setFavoriteCount, favoriteCount, setRecentPlayCount } =
    useContextAuthManager();
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({} as ErrorModal);
  const { play, favorite } = useGame({
    isLogIn,
    setFavoriteCount,
    favoriteCount,
    setRecentPlayCount,
    setIsAlertModalOpen,
    setErrorMessage,
  });
  const category = query.get(CATEGORY);
  const [games, setGames] = useState<IGame[]>([]);
  const gameAPI = useMemo(() => {
    return new GameAPI();
  }, []);

  useEffect(() => {
    let subscribe: Subscription;
    if (category) {
      subscribe = gameAPI.getSubGamesByCategory(category).subscribe({
        next: (res) => {
          setGames(res);
        },
        error: (err) => {
          console.error(err);
        },
      });
    } else {
      navigate(ROUTE.GAMES);
    }
    return () => {
      subscribe.unsubscribe();
    };
  }, [gameAPI, category, navigate]);

  const navigateType = useCallback(
    (type: IGameTypes) => {
      navigate(
        `${ROUTE.GAMES}?${createSearchParams({
          type: type.name,
        })}`
      );
    },
    [navigate]
  );

  const filter = [
    { key: "default", title: "เริ่มต้น" },
    { key: "new", title: "เกมใหม่" },
    { key: "hot", title: "เกมยอดฮิต" },
    { key: "freespin", title: "ฟรีสปิน" },
    { key: "sort", title: "เรียงตัวอักษร A-Z" },
  ];

  return (
    <div className="w-full">
      <div className="flex md:gap-3 mb-4 justify-around md:justify-start">
        {types.map((type) => {
          if (type.name === GAME_TYPE.HOT || type.name === GAME_TYPE.NEW) {
            return null;
          }
          if (useMobileMediaQuery) {
            return (
              <GameType
                key={`game-menu-${type.alt}`}
                onClick={() => navigateType(type)}
                className={
                  type.name === GAME_TYPE.SLOT
                    ? "border-[#ffc10760]"
                    : "border-primary-white/5"
                }
                src={type.src}
                title={type.title}
                alt={type.alt}
              />
            );
          } else {
            return (
              <Menu
                onClick={() => navigateType(type)}
                key={`game-menu-small-${type.alt}`}
                src={type.src}
                alt={type.alt}
                text={type.title}
              />
            );
          }
        })}
      </div>
      <Line />
      <div className="font-bold text-lg leading-7 mb-4">
        {`รายการเกมใน ${category?.toUpperCase()}`}
      </div>
      {/* Implement later */}
      <div className="hidden">
        <div>แยกโดย:</div>
        <div>
          {filter.map((item) => {
            return <div>{item.title}</div>;
          })}
        </div>
      </div>
      <GameRow>
        {games.map((game) => {
          if (useMobileMediaQuery) {
            return (
              <GameMD
                key={game.id}
                isFavorite={game.favorite}
                name={game.nameEn}
                categoryName={game.categoryName}
                favorite={() => favorite(game.id)}
                onClick={() => play(game)}
                className="min-h-[150px]"
                imgUrl={game.img}
              />
            );
          } else {
            return (
              <Fragment key={`game-fragment-${game.id}`}>
                <GameSM
                  onClick={() => play(game)}
                  key={`game-sm-${game.id}`}
                  name={game.nameEn}
                  categoryName={game.categoryName}
                  imgUrl={game.img}
                  isHover
                />
                <Line key={`game-line-${game.id}`} className="mb-0 ml-[90px]" />
              </Fragment>
            );
          }
        })}
      </GameRow>
      <AlertModal
        isOpen={isAlertModalOpen}
        setIsOpen={setIsAlertModalOpen}
        error={errorMessage}
      />
    </div>
  );
};
export default SubGames;
