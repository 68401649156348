import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import Lens from "../../assets/svg/lens-flare.svg";
import { ROUTE } from "../constant/routes";
import Tab from "./tab-bar.component";

type TitleUpProps = {
  titleEn: string;
  titleTh: string;
  hasTab?: boolean;
};

const Title = ({ titleEn, titleTh, hasTab = false }: TitleUpProps) => {
  const { pathname } = useLocation();
  const menu = [
    {
      path: ROUTE.DEPOSIT,
      title: "ฝากเงิน",
    },
    {
      path: ROUTE.WITHDRAW,
      title: "ถอนเงิน",
    },
    {
      path: ROUTE.HISTORY,
      title: "ประวัติ",
    },
  ];
  return (
    <>
      <div className="lg:hidden w-full lg:max-w-[520px]">
        <div className="font-bold text-lg leading-7 text-primary-white mb-4">
          คุณต้องการทำรายการใด
        </div>
        {hasTab && <Tab menu={menu} pathname={pathname} />}
      </div>
      <div className="hidden lg:block text-center">
        <div className="uppercase font-light text-sm leading-6 text-primary-gray">
          {titleEn}
        </div>
        <div className="font-semibold text-3xl leading-[56px]">{titleTh}</div>
        <div className="-mt-12 flex justify-center">
          <img src={Lens} alt="lens-flare" />
        </div>
      </div>
    </>
  );
};
export default Title;
