import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PromotionAPI from "../../common/api/promotion.api";
import TitleOnly from "../../common/base-ui/title-only.component";
import { ROUTE } from "../../common/constant/routes";
import { TPromotion } from "../../common/types/promotion.type";
import Card from "./component/card.component";

const Promotion = () => {
  const navigate = useNavigate();
  const [promotion, setPromotion] = useState<TPromotion[]>([]);
  const [isInit, setIsInit] = useState(false);
  useEffect(() => {
    const promotion = new PromotionAPI();
    promotion.getPromotions().subscribe({
      next: (res) => {
        setPromotion(res);
        setIsInit(true);
      },
      error: (err) => {
        setIsInit(true);
        console.error(err);
      },
    });
  }, []);
  return (
    <div className="w-full">
      <TitleOnly titleEn="Promotion" titleTh="โปรโมชั่นพิเศษจากเรา" />
      {promotion.length === 0 && isInit && (
        <div className="text-center">ยังไม่มีโปรโมชั่นในขณะนี้</div>
      )}
      {promotion.length > 0 && (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 mt-8">
          {promotion.map((item) => (
            <Card
              onClick={() =>
                navigate(ROUTE.PROMOTION_DETAIL.replace(":id", item.id))
              }
              alt={item.id}
              title={item.title}
              img={item.imgUrl}
              key={"promotion-img-" + item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default Promotion;
