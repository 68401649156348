import { useMemo } from "react"
import { createSearchParams, useNavigate } from "react-router-dom"
import PlayXBetAPI from "../api/play.api"
import { ROUTE } from "../constant/routes"
import { IGame, Launching } from "../types/game.type"
import { isDesktop, isIOS } from "react-device-detect"
import { removeToken } from "../service/local-storage"
import { GAME_TYPE } from "../constant/game-variable"
import GameAPI from "../api/game.api"
import toast from "react-hot-toast"
import { ErrorModal } from "../../common/types/modal.type"

type HookProps = {
  isLogIn: boolean
  favoriteCount?: number
  setFavoriteCount?: (count: number) => void
  setRecentPlayCount?: (count: number) => void
  setIsAlertModalOpen: (isOpen: boolean) => void
  setErrorMessage: (error: ErrorModal) => void
}

export const useGame = ({ isLogIn, setFavoriteCount, favoriteCount = 0, setRecentPlayCount, setIsAlertModalOpen, setErrorMessage }: HookProps) => {
  const navigate = useNavigate()

  const playAPI = useMemo(() => {
    return new PlayXBetAPI()
  }, [])

  const gameAPI = useMemo(() => {
    return new GameAPI()
  }, [])

  const play = (game: IGame) => {
    if (game.type === GAME_TYPE.SLOT && !game.code) {
      const query = {
        type: game.type,
        category: game.categoryName.toLowerCase(),
      }
      return navigate(`${ROUTE.SUB_GAMES}?${createSearchParams(query)}`)
    }
    if (!isLogIn) {
      return navigate(ROUTE.SIGN_IN)
    }

    let device = "PC"
    let launchingUrl = "https://auth.apixbet.com" + game.launching
    // if launching contain url
    if (game.launching.includes("https://auth.apixbet.com")) {
      launchingUrl = game.launching
    }

    let lang = "th"
    if (launchingUrl === "https://auth.apixbet.com/api/v1/member/gamev2/sbobet") {
      lang = "th-th"
    }
    const launching: Launching = {
      lang,
      launching_url: launchingUrl,
      game_code: "",
      client: "",
      name: game.nameTh,
      img: game.img,
    }
    if (game.code) {
      launching.game_code = game.code
    } else if (game.game_code) {
      launching.game_code = game.game_code
    }
    if (!isDesktop) {
      device = "MB"
    }
    launching.client = device
    playAPI.getGamesV2(game.launching, launching).subscribe({
      next: ({ code, result, message }) => {
        if (code !== 0) {
          if (code === 7001 || code === 7002) {
            removeToken()
            return navigate(ROUTE.SIGN_IN)
          }
          setIsAlertModalOpen(true)
          setErrorMessage({
            code,
            message: message,
          })
          return
        }
        if (isIOS) {
          window.location.href = result
          return
        }
        window.open(result, "_blank")
      },
      error: (error) => {
        console.error(error)
      },
    })
  }

  const favorite = (gameId: string) => {
    gameAPI.favoriteGame(gameId).subscribe({
      next: ({ countFavorite, countRecent }) => {
        const countFavoriteNew = countFavorite
        const countFavoriteOld = favoriteCount
        if (countFavoriteOld > countFavoriteNew) {
          toast.success("ลบเกมจากรายการโปรดเรียบร้อยแล้ว!")
        } else {
          toast.success("เพิ่มเกมเป็นรายการโปรดเรียบร้อยแล้ว!")
        }
        setFavoriteCount?.(countFavoriteNew)
        setRecentPlayCount?.(countRecent)
      },
      error: (error) => {
        toast.error("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง!")
        console.error(error)
      },
    })
  }

  return { play, favorite }
}
