import { cx } from "@emotion/css"
import { useMemo, useState } from "react"
import { Form } from "react-final-form"
import { toast } from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import UserAPI from "../../common/api/user.api"
import Button from "../../common/base-ui/button/button.component"
import Input from "../../common/base-ui/fields/input"
import AlertModal from "../../common/base-ui/modal/alert-modal.component"
import { ROUTE } from "../../common/constant/routes"
import { useContextAuthManager } from "../../common/context/auth.context"
import useLogo from "../../common/hooks/useLogo.hooks"
import { APP_NAME } from "../../common/service/env"
import { setRefreshToken, setToken } from "../../common/service/local-storage"
import { ErrorModal } from "../../common/types/modal.type"
import { AGENT } from "../../utils/cross-agent"

const SignIn = () => {
  const appNameCondition = APP_NAME === AGENT.shiba168 ? "168shiba" : APP_NAME
  const navigate = useNavigate()
  const { desktopLogo } = useLogo()
  const params = useParams()
  const redirect = params.redirect?.toLowerCase()

  const { setIsLogIn, lineLogin, getUserData } = useContextAuthManager()
  const [isLoading, setLoading] = useState(false)

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState({} as ErrorModal)

  const userRepository = useMemo(() => {
    return new UserAPI()
  }, [])

  const onSubmit = async (values: any) => {
    const toastId = toast.loading("กำลังเข้าสู่ระบบ...")
    setLoading(true)
    userRepository.login(values).subscribe({
      next: (res) => {
        setToken(res.token)
        setRefreshToken(res.refreshToken)
        toast.dismiss(toastId)
        toast.success("เข้าสู่ระบบสำเร็จ")
        setIsLogIn(true)
        getUserData()
        if (redirect) {
          navigate(ROUTE.REDIRECT.replace(":where", redirect))
        } else {
          navigate(ROUTE.DASHBOARD)
        }
      },
      error: (err) => {
        toast.dismiss(toastId)
        toast.error("เข้าสู่ระบบไม่สำเร็จ")
        console.error(err)
        if (err.messages) {
          setErrorMessage({
            message: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
            code: err.code,
          })
        } else {
          setErrorMessage({
            message: err.message,
            code: err.code,
          })
        }
        setIsAlertModalOpen(true)
        setLoading(false)
      },
      complete: () => {
        setLoading(false)
      },
    })
  }

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="max-w-[420px] rounded-[17px] p-4 md:bg-black/5 md:border md:border-white/[0.15] md:border-solid">
        <div className={`w-full flex ${APP_NAME === AGENT.shiba168 ? "justify-start" : "justify-center"}`}>
          <div className="mb-4 max-w-[205px]">
            <img src={desktopLogo} alt={`${APP_NAME}-logo-signin`} height="58px" width="auto" />
          </div>
        </div>
        <div>ยินดีต้อนรับ</div>
        <div className="text-lg font-bold leading-7 mb-4">เข้าสู่ระบบ เพื่อเริ่มเดิมพัน</div>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <Input
                    label="ชื่อผู้ใช้"
                    type="text"
                    name="username"
                    placeholder="ชื่อผู้ใช้"
                    className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                    required
                  />
                </div>
                <div>
                  <Input
                    label="รหัสผ่าน"
                    type="password"
                    name="password"
                    placeholder="รหัสผ่าน"
                    className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                    required
                  />
                </div>
                <Button
                  disabled={isLoading}
                  className={cx(isLoading ? "bg-gray-500" : "bg-primary-yellow", "text-primary-black px-[15px] py-2 rounded-[100px] text-sm mr-2 w-full mb-4")}
                  type="submit">
                  เข้าสู่ระบบ
                </Button>
                <div className="flex justify-center mb-4">หรือ</div>
                <Button
                  onClick={() => lineLogin()}
                  disabled={isLoading}
                  className={cx(isLoading ? "bg-gray-500" : "bg-primary-line", "text-primary-white px-[15px] py-2 rounded-[100px] text-sm mr-2 w-full mb-4")}
                  type="button">
                  เข้าสู่ระบบ / สมัครสมาชิก (LINE)
                </Button>
                {/* <Line />
                <Button
                  onClick={() => navigate(ROUTE.SIGN_UP)}
                  disabled={isLoading}
                  className={cx(
                    isLoading
                      ? "bg-gray-500 border-primary-gray"
                      : "bg-transparent border-primary-yellow",
                    "text-primary-yellow px-[15px] border-primary-yellow border py-2 rounded-[100px] text-sm mr-2 w-full mb-4"
                  )}
                  type="button"
                >
                  สมัครสมาชิก
                </Button> */}
                <div className="text-center text-sm font-normal leading-[21px] break-words">
                  {appNameCondition}.com ผู้ให้บริการคาสิโนออนไลน์อย่างเป็นทางการ สงวนลิขสิทธ์ © 2021, {appNameCondition}.com
                </div>
              </form>
            )
          }}
        />
      </div>
      <AlertModal isOpen={isAlertModalOpen} setIsOpen={setIsAlertModalOpen} error={errorMessage} />
    </div>
  )
}
export default SignIn
