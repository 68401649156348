import { cx } from "@emotion/css";
import { useNavigate } from "react-router-dom";

type ITab = {
  path: string;
  title: string;
};

type TabProps = {
  menu: ITab[];
  pathname: string;
};

const Tab = ({ menu, pathname }: TabProps) => {
  const navigate = useNavigate();

  return (
    <div className="bg-primary-white/5 w-full mb-4 rounded-lg grid grid-cols-3 px-[3px] py-[3px]">
      {menu.map((item, index) => {
        return (
          <div
            key={`tabs-${index}`}
            onClick={() => navigate(item.path)}
            className={cx(
              "text-center p-2 rounded-md",
              pathname === item.path &&
                "bg-gradient-to-r from-[#3b64cc] to-[#671fda]"
            )}
          >
            {item.title}
          </div>
        );
      })}
    </div>
  );
};
export default Tab;
