import { ReactComponent as Deposit } from "../../../assets/svg/deposit.svg";
import { ReactComponent as Withdraw } from "../../../assets/svg/withdraw.svg";
import { ReactComponent as Cancel } from "../../../assets/svg/cancel.svg";

export const enum ICON_NAME {
  deposit = "deposit",
  withdraw = "withdraw",
  cancel = "cancel",
}

type IProps = {
  name: ICON_NAME;
};
const TopUpIcon = (props: IProps) => {
  const { name } = props;
  const icon = {
    deposit: <Deposit {...props} width={38} height={38} />,
    withdraw: <Withdraw {...props} width={38} height={38} />,
    cancel: <Cancel {...props} width={20} height={20} />,
  };
  return icon[name];
};
export default TopUpIcon;
