import Lens from "../../assets/svg/lens-flare.svg";

type TitleOnlyProps = {
  titleEn: string;
  titleTh: string;
};

const TitleOnly = ({ titleEn, titleTh }: TitleOnlyProps) => {
  return (
    <div className="text-center">
      <div className="uppercase font-light text-sm leading-6 text-primary-gray">
        {titleEn}
      </div>
      <div className="font-semibold text-3xl leading-[56px]">{titleTh}</div>
      <div className="-mt-12 flex justify-center">
        <img src={Lens} alt="lens-flare" />
      </div>
    </div>
  );
};
export default TitleOnly;
