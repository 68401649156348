import { AGENT } from "../../utils/cross-agent";
import { APP_NAME } from "../service/env";

import UniMobileLogo from "../../static/uni168/uni168-icon.png";
import UniDesktopLogo from "../../static/uni168/uni168.png";

import ShibaMobileLogo from "../../static/shiba168/shiba168-icon.png";
import ShibaDesktopLogo from "../../static/shiba168/shiba168.png";

import NekoMobileLogo from "../../static/neko168/neko168-icon.png";
import NekoDesktopLogo from "../../static/neko168/neko168.png";

const useLogo = () => {
  const getProviderLogo = () => {
    if (APP_NAME === AGENT.uni168) {
      return {
        mobileLogo: UniMobileLogo,
        desktopLogo: UniDesktopLogo,
      };
    } else if (APP_NAME === AGENT.shiba168) {
      return {
        mobileLogo: ShibaMobileLogo,
        desktopLogo: ShibaDesktopLogo,
      };
    } else if (APP_NAME === AGENT.neko168) {
      return {
        mobileLogo: NekoMobileLogo,
        desktopLogo: NekoDesktopLogo,
      };
    } else {
      return {
        mobileLogo: "",
        desktopLogo: "",
      };
    }
  };
  return {
    mobileLogo: getProviderLogo().mobileLogo,
    desktopLogo: getProviderLogo().desktopLogo,
  };
};
export default useLogo;
