import PromptPayLogo from "../../../assets/images/prompt-pay-logo.png"
import ClickIcon from "../../../assets/images/click.png"
import { IPromptPay } from "../../../common/types/topup.type"
import { css, cx } from "@emotion/css"

type Props = {
  promptPay: IPromptPay[]
  handleClickPromptPay: (id: string) => void
}

const PromptPayCard = ({ promptPay, handleClickPromptPay }: Props) => {
  const clickHere = css`
    animation: clickHere 0.7s ease infinite;

    @keyframes clickHere {
      0% {
        transform: scale(0.8);
      }
      25% {
        transform: scale(0.8);
      }
      50% {
        transform: scale(1);
      }
      75% {
        transform: scale(0.8);
      }
      100% {
        transform: scale(0.8);
      }
    }
  `

  return (
    <>
      {promptPay?.map((prompt) => (
        <div
          onClick={() => handleClickPromptPay(prompt.id)}
          className="flex justify-between items-center gap-2 border border-1 border-white p-4 rounded-xl hover:bg-[#f5f5f533] cursor-pointer mb-4 w-full lg:max-w-[520px]">
          <div className="bg-white">
            <img src={PromptPayLogo} alt="prompt-pay-logo" width={"200px"} />
          </div>
          <div>{prompt.bankName}</div>
          <img className={cx(clickHere)} src={ClickIcon} alt="click-icon" width={"50px"} />
        </div>
      ))}
    </>
  )
}

export default PromptPayCard
