import { createRef, useEffect } from "react"
import { APP_NAME, LINE_OFFICIAL_LINK } from "../../../common/service/env"
import QRCode from "easyqrcodejs"
import { isMobile } from "react-device-detect"
const LineCard = () => {
  const code = createRef<HTMLDivElement>()
  let isInit = false
  useEffect(() => {
    if (!isInit) {
      let options = {
        text: LINE_OFFICIAL_LINK,
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/LINE_logo.svg/1024px-LINE_logo.svg.png",
      } as any
      if (isMobile) {
        options = {
          ...options,
          width: 150,
          height: 150,
          quietZone: 10,
        }
      } else {
        options = {
          ...options,
          width: 130,
          height: 130,
          quietZone: 20,
        }
      }
      new QRCode(code.current, options)
      // eslint-disable-next-line
      isInit = true
    }
  }, [code])
  return (
    <div className="p-4">
      <div className="flex flex-col xl:flex-row gap-6 items-center bg-primary-line rounded-xl p-5">
        <div className="flex flex-col justify-center items-center xl:items-start">
          <h1 className="text-base font-semibold mb-2.5">ติดต่อเรา</h1>
          <p className="text-sm text-describe mb-2.5">
            ติดต่อสำนักงาน {APP_NAME.toUpperCase()} ท่านสามารถติดต่อสอบถามทางทีมงาน ได้ทุกเรื่องที่เกี่ยวกับ คาสิโนออนไลน์ และ กีฬาออนไลน์ ไม่ว่าจะ เติมเงิน
            ถอนเงิน โยกเงิน หรือเช็คยอดเงิน
          </p>
          <div
            onClick={() => window.open(LINE_OFFICIAL_LINK, "_blank")}
            className="cursor-pointer flex items-center w-fit bg-white rounded-lg py-1.5 px-4 text-primary-line">
            {/* <img
              src="https://cdn.worldvectorlogo.com/logos/line-messenger.svg"
              alt=""
              className="w-6 mr-1.5"
            /> */}
            @{APP_NAME.toUpperCase()}
          </div>
        </div>
        <div ref={code}></div>
      </div>
    </div>
  )
}
export default LineCard
