import WithdrawCard from "./component/withdraw-card.component"
import { useEffect, useMemo, useState } from "react"
import { IBankAccount } from "../../common/types/topup.type"
import { ICON_NAME } from "../../common/base-ui/icon/icon.component"
import TopUpAPI from "../../common/api/top-up.api"
import { useNavigate } from "react-router-dom"
import { ROUTE } from "../../common/constant/routes"
import WithdrawForm from "./component/withdraw-form.component"
import Title from "../../common/base-ui/title.component"
import UserAPI from "../../common/api/user.api"
import { ErrorModal } from "../../common/types/modal.type"
import AlertModal from "../../common/base-ui/modal/alert-modal.component"
import useLogo from "../../common/hooks/useLogo.hooks"
import toast from "react-hot-toast"

const Withdraw = () => {
  const navigate = useNavigate()
  const [userBank, setUserBank] = useState<IBankAccount>()
  const [balance, setBalance] = useState(0)
  const [isLoading] = useState(false)
  const [error, setError] = useState("")

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState({} as ErrorModal)

  const { mobileLogo } = useLogo()

  const userRepository = useMemo(() => {
    return new UserAPI()
  }, [])
  const topUpXBetRepository = useMemo(() => {
    return new TopUpAPI()
  }, [])

  useEffect(() => {
    userRepository.bankAccount().subscribe({
      next: (res) => {
        setUserBank(res.userBank)
      },
      error: (err) => {
        console.error(err)
        if (err.code === 401) {
          setErrorMessage({
            message: "Session ของคุณหมดอายุ กรุณาล็อคอินใหม่อีกครั้ง",
            code: 401,
            gotoLogin: true,
          })
          setIsAlertModalOpen(true)
        }
      },
    })

    userRepository.credit().subscribe({
      next: (res) => {
        setBalance(res.balance)
      },
      error: (err) => {
        console.error(err)
      },
    })
  }, [userRepository, navigate])

  const onSubmit = async (values: any) => {
    const amount = parseFloat(values.amount)
    if (isNaN(amount)) {
      return setError("กรุณากรอกเฉพาะตัวเลข")
    }
    if (amount < 300) {
      return setError("ถอนขั้นต่ำ 300 บาท")
    }
    setError("")
    topUpXBetRepository.withdrawV2(amount).subscribe({
      next: ({ code, message }) => {
        if (code === 0) {
          toast.success("ทำรายการถอนเงินสำเร็จ")
          return navigate(ROUTE.HISTORY)
        }
        toast.error("ทำรายการถอนเงินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่")
        console.error("เกิดข้อผิดพลาด", message) // alert
      },
      error: (err) => {
        console.error(err)
      },
    })
  }

  return (
    <>
      <Title titleEn="Withdrawal" titleTh="ทำรายการถอนเงิน" hasTab />
      <WithdrawCard
        webLogo={mobileLogo}
        bankLogo={userBank?.bank.bankTag as ICON_NAME}
        bankName={userBank?.bank.bankName}
        bankAccountNumber={userBank?.number}
        bankAccountName={userBank?.name}
      />
      <WithdrawForm balance={balance} onSubmit={onSubmit} error={error} isLoading={isLoading} />
      <AlertModal isOpen={isAlertModalOpen} setIsOpen={setIsAlertModalOpen} error={errorMessage} />
    </>
  )
}
export default Withdraw
