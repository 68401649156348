import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type AffMenuProps = {
  title: string;
  desc: string;
  img: string;
  onClick: () => void;
};

const AffMenu = ({ title, desc, img, onClick }: AffMenuProps) => {
  return (
    <div
      onClick={onClick}
      className="flex gap-4 cursor-pointer items-center hover:text-yellow text-sm mb-2 hover:text-primary-yellow"
    >
      <div className="flex-none">
        <img src={img} alt={title} className="w-9" />
      </div>
      <div className="flex-grow">
        <h3 className="font-bold">{title}</h3>
        <h2 className="text-primary-gray">{desc}</h2>
      </div>
      <div className="flex-none text-lg font-bold ">
        <FontAwesomeIcon icon={faChevronCircleRight} />
      </div>
    </div>
  );
};
export default AffMenu;
