export const getBankByBankId = (bankId) => {
  switch (bankId) {
    case 1:
      return { id: 1, bankTag: 'scb', bankName: 'ธนาคารไทยพาณิชย์' }
    case 2:
      return { id: 2, bankTag: 'kbank', bankName: 'ธนาคารกสิกรไทย' }
    case 3:
      return { id: 3, bankTag: 'ktb', bankName: 'ธนาคารกรุงไทย' }
    case 4:
      return { id: 4, bankTag: 'bbl', bankName: 'ธนาคารกรุงเทพ' }
    case 5:
      return { id: 5, bankTag: 'bay', bankName: 'ธนาคารกรุงศรี' }
    case 6:
      return { id: 6, bankTag: 'tbank', bankName: 'ธนาคารธนชาต(เลิกให้บริการ)' }
    case 7:
      return { id: 7, bankTag: 'tmb/ttb', bankName: 'ธนาคารทหารไทยธนชาต' }
    case 8:
      return { id: 8, bankTag: 'gsb', bankName: 'ธนาคารออมสิน' }
    case 9:
      return { id: 9, bankTag: 'baac', bankName: 'ธนาคารเพื่อการเกษตร' }
    case 10:
      return { id: 10, bankTag: 'isbt', bankName: 'ธนาคารอิสลาม' }
    case 11:
      return { id: 11, bankTag: 'uob', bankName: 'ธนาคารยูโอบี' }
    case 12:
      return { id: 12, bankTag: 'cimb', bankName: 'ธนาคารซีไอเอ็มบี' }
    case 13:
      return { id: 13, bankTag: 'citi', bankName: 'ธนาคารซิตี้แบงค์' }
    case 14:
      return { id: 14, bankTag: 'lh', bankName: 'ธนาคารแลนด์แอนด์เฮ้าส์' }
    case 15:
      return { id: 15, bankTag: 'owl', bankName: 'OneWallet' }
    case 16:
      return { id: 16, bankTag: 'kkp', bankName: 'ธนาคารเกียรตินาคิน' }
    case 17:
      return { id: 17, bankTag: 'ghb', bankName: 'ธนาคารอาคารสงเคราะห์' }
    default:
      break
  }
  return { bankTag: 'unknown', bankName: 'ไม่พบธนาคาร' }
}

export const getBanks = () => {
  return [
    {
      id: 1,
      bankTag: 'scb',
      bankName: 'ธนาคารไทยพาณิชย์'
    },
    {
      id: 2,
      bankTag: 'kbank',
      bankName: 'ธนาคารกสิกรไทย'
    },
    {
      id: 3,
      bankTag: 'ktb',
      bankName: 'ธนาคารกรุงไทย'
    },
    {
      id: 4,
      bankTag: 'bbl',
      bankName: 'ธนาคารกรุงเทพ'
    },
    {
      id: 5,
      bankTag: 'bay',
      bankName: 'ธนาคารกรุงศรี'
    },
    {
      id: 7,
      bankTag: 'tmb/ttb',
      bankName: 'ธนาคารทหารไทยธนชาต'
    },
    {
      id: 8,
      bankTag: 'gsb',
      bankName: 'ธนาคารออมสิน'
    },
    {
      id: 9,
      bankTag: 'baac',
      bankName: 'ธนาคารเพื่อการเกษตร'
    },
    {
      id: 10,
      bankTag: 'isbt',
      bankName: 'ธนาคารอิสลาม'
    },
    {
      id: 11,
      bankTag: 'uob',
      bankName: 'ธนาคารยูโอบี'
    },
    {
      id: 12,
      bankTag: 'cimb',
      bankName: 'ธนาคารซีไอเอ็มบี'
    },
    {
      id: 13,
      bankTag: 'citi',
      bankName: 'ธนาคารซิตี้แบงค์'
    }
  ]
}
