import { cx } from "@emotion/css";
import { IMAGE_CDN_URL } from "../../constant/cdn";

type GameSMProps = {
  categoryName: string;
  name: string;
  imgUrl: string;
  isHover?: boolean;
  onClick: () => void;
};
const GameSM = ({
  categoryName,
  name,
  imgUrl,
  isHover = false,
  onClick,
}: GameSMProps) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        "flex flex-row items-center justify-between cursor-pointer",
        isHover
          ? "hover:scale-110 hover:ease-in-out hover:duration-[250ms]"
          : ""
      )}
    >
      <div className="flex flex-row items-center">
        <div className="border border-solid border-primary-white/[0.15] rounded-xl overflow-hidden w-fit">
          <img
            className="w-full min-h-[70px] max-w-[80px] lg:max-w-[90px] min-w-[80px] lg:min-w-[90px] object-cover"
            alt={name}
            src={`${IMAGE_CDN_URL + imgUrl}`}
          />
        </div>
        <div className="ml-2">
          <div className="font-light text-xs leading-4 text-primary-white/50">
            {categoryName}
          </div>
          <div className="text-highlight truncate w-[120px] lg:w-[69px] xl:w-[120px]">
            {name}
          </div>
        </div>
      </div>
      <div
        onClick={onClick}
        className="text-primary-yellow text-xs bg-primary-black/[0.15] border border-solid border-primary-white/5 rounded-3xl py-1 px-2 lg:px-4 min-w-[69px] text-center"
      >
        เดิมพัน
      </div>
    </div>
  );
};
export default GameSM;
