import { Toaster } from "react-hot-toast";
import { LiffProvider } from "react-liff";
import { BrowserRouter } from "react-router-dom";
import AuthManager from "./common/context/auth.context";
import { LINE_LIFF_ID } from "./common/service/env";
import Router from "./router/router";

const App = () => {
  return (
    <BrowserRouter>
      <LiffProvider liffId={LINE_LIFF_ID}>
        <AuthManager>
          <Router />
          <Toaster
            position="top-right"
            reverseOrder={false}
            toastOptions={{
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            }}
          />
        </AuthManager>
      </LiffProvider>
    </BrowserRouter>
  );
};

export default App;
