import Close from "../../assets/images/cross.png";
import { css, cx } from "@emotion/css";
import { isMobile } from "react-device-detect";

type ActivityProps = {
  url: string;
  image: string;
  onClick?: () => void;
};
const Activity = ({ url, image, onClick }: ActivityProps) => {
  const hitHere = css`
    animation: hithere 1.5s ease infinite;

    @keyframes hithere {
      30% {
        transform: scale(1.2);
      }
      40%,
      60% {
        transform: rotate(-20deg) scale(1.2);
      }
      50% {
        transform: rotate(20deg) scale(1.2);
      }
      70% {
        transform: rotate(0deg) scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  `;

  return (
    <div className="fixed bottom-[120px] right-[35px] md:right-[78px]">
      <div className="relative">
        <img
          onClick={onClick}
          className="absolute -right-[15px] -top-[35px] cursor-pointer"
          src={Close}
          alt="close-activity"
          width="25px"
          height="25px"
        />
        <img
          onClick={() => window.open(url, "_blank")}
          className={cx(hitHere, "cursor-pointer")}
          src={image}
          alt="activity-img"
          width={isMobile ? "80px" : "100px"}
          height={isMobile ? "80px" : "100px"}
        />
      </div>
    </div>
  );
};
export default Activity;
