import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/css/index.scss";
import { APP_NAME } from "./common/service/env";

import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
document.title = `${APP_NAME.toUpperCase()} | พนันออนไลน์ บาคาร่า คาสิโน สล๊อต หวย และ เกมส์ออนไลน์ รวมไว้ให้ท่านแล้วที่นี่ พร้อม โปรโมชั่น อีกมากมาย`;
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
