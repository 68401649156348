import { getLineId } from "../service/local-storage"
import { useNavigate } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import { useLiff } from "react-liff"
import { defaultUsernameAgent } from "../../utils/cross-agent"
import UserAPI from "../api/user.api"
import useQuery from "../hooks/useQuery.hooks"
import { getAffIdLocal, getIntroduceIdLocal, getToken, removeRefreshToken, removeToken, setAffIdLocal, setIntroduceIdLocal } from "../service/local-storage"
import { isMobile } from "react-device-detect"
import { Subscription } from "rxjs"
import { ROUTE } from "../constant/routes"
import { LINE_LIFF_ID } from "../service/env"

export const useAuth = () => {
  const query = useQuery()
  const navigate = useNavigate()
  const queryAffId = query.get("affId") || ""
  const queryIntroduceId = query.get("introduceId")
  const { isLoggedIn, liff } = useLiff()
  const [isLogIn, setIsLogIn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [favoriteCount, setFavoriteCount] = useState(0)
  const [recentPlayCount, setRecentPlayCount] = useState(0)
  const [balance, setBalance] = useState(0)
  const [username, setUsername] = useState(defaultUsernameAgent())
  const [affId, setAffId] = useState("")
  const [introduceId, setIntroduceId] = useState("")
  const [lineUserId, setLineUserId] = useState(getLineId() || "")
  const [isShowContactUs, setIsShowContactUs] = useState(false)

  useEffect(() => {
    if (queryAffId || queryIntroduceId) {
      setAffIdLocal(queryAffId)
      setIntroduceIdLocal(queryIntroduceId || "7")

      setAffId(queryAffId)
      setIntroduceId(queryIntroduceId || "7")
    } else {
      setAffId(getAffIdLocal())
      setIntroduceId(getIntroduceIdLocal())
    }
  }, [affId, introduceId, queryAffId, queryIntroduceId])

  const lineLogin = () => {
    if (isMobile) {
      if (affId) {
        window.location.href = `line://app/${LINE_LIFF_ID}?affId=${affId}&introduceId=6`
      } else {
        window.location.href = "line://app/" + LINE_LIFF_ID
      }
    } else {
      liff.login()
    }
  }

  const logout = () => {
    setIsLogIn(false)
    removeRefreshToken()
    removeToken()
    liff.logout()
    navigate(ROUTE.DASHBOARD)
  }

  const destroy = useCallback(() => {
    setIsLogIn(false)
    removeRefreshToken()
    removeToken()
  }, [])

  const getUserData = () => {
    const userAPI = new UserAPI()
    return userAPI.credit().subscribe({
      next: ({ balance, username }) => {
        setBalance(balance)
        setUsername(username)
        setIsLogIn(true)
      },
      error: (err) => {
        console.error(err)
        destroy()
      },
    })
  }

  useEffect(() => {
    const getUserData = () => {
      const userAPI = new UserAPI()
      return userAPI.credit().subscribe({
        next: ({ balance, username }) => {
          setBalance(balance)
          setUsername(username)
          setIsLogIn(true)
        },
        error: (err) => {
          console.error(err)
          destroy()
        },
      })
    }
    let subscribe = new Subscription()
    const token = getToken()
    if (token) {
      subscribe = getUserData()
    } else {
      destroy()
      return
    }
    return () => {
      subscribe.unsubscribe()
    }
  }, [destroy])

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [isLoading])

  return {
    isLogIn,
    setIsLogIn,
    setIsLoading,
    isLoading,
    logout,
    favoriteCount,
    setFavoriteCount,
    recentPlayCount,
    setRecentPlayCount,
    balance,
    username,
    lineLogin,
    affId,
    introduceId,
    lineUserId,
    isLoggedIn,
    liff,
    setLineUserId,
    getUserData,
    setIsShowContactUs,
    isShowContactUs,
  }
}
