import { cx } from "@emotion/css";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

type ModalProps = {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
  overlayClassName?: string;
};

const Modal = ({
  setIsOpen,
  isOpen,
  children,
  className,
  overlayClassName,
}: ModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={cx(
              "fixed inset-0 bg-black bg-opacity-25",
              overlayClassName
            )}
          />
        </Transition.Child>

        <div className="fixed inset-0 lg:!left-[188px] overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={cx(
                  "w-full max-w-md transform overflow-hidden rounded-2xl bg-[#21262f] p-6 text-left align-middle shadow-xl transition-all",
                  className
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default Modal;
