import { css, cx } from "@emotion/css";
import { APP_NAME } from "../service/env";

const Copyright = () => {
  return (
    <div
      className={cx(
        "font-light text-[11px] text-primary-white text-center",
        css`
          color: rgba(255, 255, 255, 0.75);
        `
      )}
    >
      {APP_NAME.toUpperCase()}.com ได้รับอนุญาตและควบคุมโดยรัฐบาล Curaçao
      ภายใต้ใบอนุญาตการเล่นเกม 1668
    </div>
  );
};
export default Copyright;
