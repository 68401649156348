export const getToken = () => {
  return localStorage.getItem("token");
};

export const setToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const setRefreshToken = (token: string) => {
  localStorage.setItem("refreshToken", token);
};

export const removeRefreshToken = () => {
  localStorage.removeItem("refreshToken");
};

export const getUsername = () => {
  return localStorage.getItem("username");
};

export const setUsername = (username: string) => {
  localStorage.setItem("username", username);
};

export const removeUsername = () => {
  localStorage.removeItem("username");
};

export const getAffIdLocal = () => {
  return localStorage.getItem("affId") || "";
};

export const setAffIdLocal = (affId: string) => {
  localStorage.setItem("affId", affId);
};

export const getIntroduceIdLocal = () => {
  return localStorage.getItem("introduceId") || "7";
};

export const setIntroduceIdLocal = (introduceId: string) => {
  localStorage.setItem("introduceId", introduceId);
};

export const getMigrateUsername = () => localStorage.getItem("migrateUsername");
export const setMigrateUsername = (username: string) =>
  localStorage.setItem("migrateUsername", username);
export const removeMigrateUsername = () =>
  localStorage.removeItem("migrateUsername");

export const getMigratePassword = () => localStorage.getItem("migratePassword");
export const setMigratePassword = (password: string) =>
  localStorage.setItem("migratePassword", password);
export const removeMigratePassword = () =>
  localStorage.removeItem("migratePassword");

export const getLineId = () => localStorage.getItem("lineId");
export const setLineId = (lineId: string) =>
  localStorage.setItem("lineId", lineId);
export const removeLineId = () => localStorage.removeItem("lineId");
