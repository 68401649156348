import { useState, useMemo, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Subscription } from "rxjs";
import GameAPI from "../../common/api/game.api";
import GameMD from "../../common/base-ui/games/game-md.component";
import GameRow from "../../common/base-ui/games/game-row.component";
import GameSM from "../../common/base-ui/games/game-sm.component";
import AlertModal from "../../common/base-ui/modal/alert-modal.component";
import { ROUTE } from "../../common/constant/routes";
import { useContextAuthManager } from "../../common/context/auth.context";
import { useGame } from "../../common/hooks/useGame.hooks";
import { useMediaSize } from "../../common/hooks/useMedia.hook";
import useQuery from "../../common/hooks/useQuery.hooks";
import { IGame } from "../../common/types/game.type";
import { ErrorModal } from "../../common/types/modal.type";
import Line from "../../layout/line.component";

const Search = () => {
  const navigate = useNavigate();
  const { useMobileMediaQuery } = useMediaSize();
  const query = useQuery();
  const keyword = query.get("keyword");
  const { isLogIn } = useContextAuthManager();

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({} as ErrorModal);
  const { play } = useGame({ isLogIn, setIsAlertModalOpen, setErrorMessage });

  const [games, setGames] = useState<IGame[]>([]);
  const gameAPI = useMemo(() => {
    return new GameAPI();
  }, []);

  useEffect(() => {
    let subscribe: Subscription;
    if (keyword) {
      subscribe = gameAPI.getGamesByKeyword(keyword).subscribe({
        next: (res) => {
          setGames(res);
        },
        error: (err) => {
          console.error(err);
        },
      });
    } else {
      navigate(ROUTE.DASHBOARD);
    }
    return () => {
      subscribe.unsubscribe();
    };
  }, [gameAPI, keyword, navigate]);

  return (
    <div className="w-full">
      <div className="font-semibold text-lg leading-7 mb-4">
        ผลการค้นหา "{keyword}" พบ {games.length} รายการ
      </div>
      <GameRow>
        {games.map((game) => {
          if (useMobileMediaQuery) {
            return (
              <GameMD
                isFavorite={game.favorite}
                key={`search-${game.id}`}
                className="min-h-[150px]"
                name={game.nameEn}
                categoryName={game.categoryName}
                favorite={() => {}}
                onClick={() => play(game)}
                imgUrl={game.img}
              />
            );
          } else {
            return (
              <Fragment key={`game-fragment-${game.id}`}>
                <GameSM
                  onClick={() => play(game)}
                  key={`game-sm-${game.id}`}
                  name={game.nameEn}
                  categoryName={game.categoryName}
                  imgUrl={game.img}
                  isHover
                />
                <Line key={`game-line-${game.id}`} className="mb-0 ml-[90px]" />
              </Fragment>
            );
          }
        })}
      </GameRow>
      <AlertModal
        isOpen={isAlertModalOpen}
        setIsOpen={setIsAlertModalOpen}
        error={errorMessage}
      />
    </div>
  );
};
export default Search;
