import { cx } from "@emotion/css";
import Button from "../common/base-ui/button/button.component";
import { useContextAuthManager } from "../common/context/auth.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useCallback, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ROUTE } from "../common/constant/routes";
import { useMediaSize } from "../common/hooks/useMedia.hook";
import useLogo from "../common/hooks/useLogo.hooks";
import { APP_NAME } from "../common/service/env";

const AuthorizedMenu = ({ noNavbar }: any) => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const navigateSearch = useCallback(() => {
    navigate(
      `${ROUTE.SEARCH}?${createSearchParams({
        keyword,
      })}`
    );
  }, [keyword, navigate]);
  return (
    <Fragment>
      {!noNavbar && (
        <div className="flex items-center justify-between w-full">
          <FontAwesomeIcon
            onClick={() => navigate(-1)}
            className="mr-4 cursor-pointer"
            icon={faChevronLeft}
          />
          <div className="rounded-[20px] h-[38px] flex items-center bg-white/5">
            <input
              type="text"
              name="search"
              placeholder="ลองค้นหาคำว่า โรม่า ..."
              onChange={(e) => setKeyword(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && navigateSearch()}
              className="w-[200px] text-primary-white border-0 outline-0 bg-transparent leading-6 px-[15px]"
            />
            <FontAwesomeIcon
              onClick={() => navigateSearch()}
              className="mr-4 cursor-pointer"
              icon={faSearch}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

type UnauthorizedMenuProps = {
  isLogIn: boolean;
  lineLogin: () => void;
  isLoading: boolean;
  isMobile: boolean;
};

const UnauthorizedMenu = ({
  isLogIn,
  lineLogin,
  isLoading,
  isMobile,
}: UnauthorizedMenuProps) => {
  const navigate = useNavigate();
  const { mobileLogo } = useLogo();
  return (
    <Fragment>
      {isLogIn ? (
        <div className="rounded-[20px] h-[38px] flex items-center bg-white/5">
          <input
            type="text"
            name="search"
            placeholder="ลองค้นหาคำว่า โรม่า ..."
            className="w-[200px] text-primary-white border-0 outline-0 bg-transparent leading-6 px-[15px]"
          />
          <FontAwesomeIcon className="mr-4 cursor-pointer" icon={faSearch} />
        </div>
      ) : (
        <div className="flex items-center">
          <div
            className="cursor-pointer lg:hidden"
            onClick={() => navigate(ROUTE.DASHBOARD)}
          >
            <img
              src={mobileLogo}
              alt={`${APP_NAME}-logo-sidebar`}
              width={28}
              height={28}
            />
          </div>
        </div>
      )}
      <div className="flex">
        <Button
          onClick={() => lineLogin()}
          className={cx(
            isLoading ? "bg-gray-500" : "bg-primary-line",
            "text-primary-white px-[15px] py-[6px] rounded-[100px] text-sm mr-2"
          )}
          type="button"
        >
          {!isMobile ? (
            <span>เข้าสู่ระบบ</span>
          ) : (
            <span>เข้าสู่ระบบด้วย Line</span>
          )}
        </Button>
        <Button
          onClick={() => navigate(ROUTE.SIGN_IN)}
          className={cx(
            isLogIn ? "bg-gray-500" : "bg-primary-yellow",
            "text-primary-black px-[15px] py-[6px] rounded-[100px] text-sm mr-2"
          )}
          type="button"
        >
          {!isMobile ? (
            <span>สมัครสมาชิก</span>
          ) : (
            <span>เข้าสู่ระบบ / สมัครสมาชิก</span>
          )}
        </Button>
      </div>
    </Fragment>
  );
};

const Navbar = ({ noNavbar }: any) => {
  const { useMobileMediaQuery } = useMediaSize();
  const { isLogIn, lineLogin, isLoading } = useContextAuthManager();
  return (
    <div
      className={cx(
        "lg:ml-[220px] px-5 flex items-center justify-between bg-black/25",
        isLogIn && noNavbar ? "" : "py-2"
      )}
    >
      {isLogIn ? (
        <AuthorizedMenu noNavbar={noNavbar} />
      ) : (
        <UnauthorizedMenu
          isMobile={useMobileMediaQuery}
          isLogIn={isLogIn}
          lineLogin={lineLogin}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
export default Navbar;
