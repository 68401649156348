import TitleOnly from "../../common/base-ui/title-only.component"
import { APP_NAME, LINE_OFFICIAL_LINK } from "../../common/service/env"
import { AGENT } from "../../utils/cross-agent"
import DisclosureComponent from "./component/disclosure.component"
import LineCard from "./component/line-card.component"

const Help = () => {
  const appNameCondition = APP_NAME === AGENT.shiba168 ? "168shiba" : APP_NAME
  const helpText = [
    {
      title: "1. ฝาก - ถอนขั้นต่ำเท่าไหร่ ?",
      children: (
        <div className="p-2">
          <ol className="w-full list-decimal marker:text-primary-yellow leading-0">
            <li className="ml-3">
              <p className="text-gray break">ฝากขั้นต่ำ 1 บาท</p>
            </li>
            <li className="ml-3">
              <p className="text-gray">ถอนขั้นต่ำ 300 บาท</p>
            </li>
          </ol>
        </div>
      ),
    },
    {
      title: "2. ฉันจะทำการฝากเงินได้อย่างไร ?",
      children: (
        <div className="p-2">
          <p className="text-gray mb-4 ml-3">เมื่อสร้างบัญชีและเข้าสู่ระบบแล้ว</p>
          <ol className="w-full list-decimal marker:text-primary-yellow leading-0">
            <li className="ml-3">
              <p className="text-gray break">คลิกที่ปุ่มฝากเงิน เพื่อไปยังหน้าแสดงหมายเลขบัญชี จากนั้นคัดลอกหมายเลขบัญชีโดยกดที่ปุ่มคัดลอก</p>
            </li>
            <li className="ml-3">
              <p className="text-gray">
                ให้ท่านโอนเงินไปยังหมายเลขบัญชีที่คัดลอก โดยใช้บัญชีที่ท่านได้สมัครไว้กับทางเว็บเท่านั้น หากใช้บัญชีอื่น ยอดเครดิตจะไม่เข้า
              </p>
            </li>
            <li className="ml-3">
              <p className="text-gray">หลังจากโอนเงินแล้วลูกค้าจะได้รับการแจ้งเตือนว่ามีเครดิตเข้าแล้ว</p>
            </li>
          </ol>
        </div>
      ),
    },
    {
      title: "3. ทำไมฉันเข้าเกมไม่ได้ ?",
      children: (
        <div className="p-2">
          <ol className="w-full list-decimal marker:text-primary-yellow leading-0">
            <li className="ml-3">
              <p className="text-gray break">
                ทำการคัดลอกลิงค์
                <a href={`https://app.${appNameCondition}.com/`} className="text-yellow">
                  {" "}
                  app.{appNameCondition}.com
                </a>
              </p>
            </li>
            <li className="ml-3">
              <p className="text-gray">นำลิงค์ที่คัดลอกไปเปิดใน Google Chrome, Safari (ไม่แนะนำ Samsung Browser)</p>
            </li>
          </ol>
        </div>
      ),
    },
    {
      title: "4. ฉันจะทำการถอนเงินได้อย่างไร ?",
      children: (
        <div className="p-2">
          <p className="text-gray mb-4 ml-3">เมื่อสร้างบัญชีและเข้าสู่ระบบแล้ว</p>
          <ol className="w-full list-decimal marker:text-primary-yellow leading-0">
            <li className="ml-3">
              <p className="text-gray break">คลิกที่ปุ่มถอนเงิน เพื่อไปยังหน้าหน้าถอนเงิน</p>
            </li>
            <li className="ml-3">
              <p className="text-gray">ทำการกรอกจำนวนเงินที่ต้องการถอน แล้วกดปุ่มถอนเงิน</p>
            </li>
            <li className="ml-3">
              <p className="text-gray">รอ 1-3 นาที ยอดเงินจะเข้าบัญชีของท่านอัตโนมัติ</p>
            </li>
          </ol>
        </div>
      ),
    },
    {
      title: "5. ถ้าฉันลืมรหัสผ่านฉันจะทำอย่างไร ?",
      children: (
        <div className="p-2">
          <p className="text-gray break  ml-3">
            ติดต่อแอดมินที่ไลน์
            <a href={LINE_OFFICIAL_LINK} className="text-yellow">
              {" "}
              {APP_NAME.toUpperCase()}
            </a>
          </p>
          <p className="text-gray mb-4 ml-3">หรือท่านสามารถเปลี่ยนรหัสด้วยตัวเองได้โดย</p>
          <ol className="w-full list-decimal marker:text-primary-yellow leading-0">
            <li className="ml-3">
              <p className="text-gray break">ให้ท่านไปที่หน้าเข้าสู่ระบบ</p>
            </li>
            <li className="ml-3">
              <p className="text-gray">คลิกที่ ลืมรหัสผ่าน? อยู่ล่างซ้าย</p>
            </li>
            <li className="ml-3">
              <p className="text-gray">
                ทำการกรอกเบอร์โทรศัพท์, เลือกธนาคารที่ท่านใช้ในการสมัครสมาชิก, กรอกหมายเลขบัญชีธนาคารที่ท่านใช้ในการสมัครสมาชิก จากนั้นคลิกที่ปุ่ม ดำเนินการต่อ
              </p>
            </li>
            <li className="ml-3">
              <p className="text-gray">ท่านจะได้รับรหัส OTP ทาง SMS นำรหัสที่ได้มากรอกลงในช่อง OTP จากนั้นคลิกที่ปุ่ม ดำเนินการต่อ</p>
            </li>
            <li className="ml-3">
              <p className="text-gray">ระบบจะพาคุณไปยังหน้าตั้งรหัสผ่านใหม่ และให้คุณทำการตั้งรหัสผ่านใหม่ของคุณ</p>
            </li>
          </ol>
        </div>
      ),
    },
  ]

  return (
    <div className="w-full">
      <TitleOnly titleEn="Help Center" titleTh="ศูนย์ช่วยเหลือ" />
      <div className="flex flex-col lg:grid lg:grid-cols-2 text-white">
        <div>
          <h1 className="text-white font-bold text-lg">คำถามที่พบบ่อย</h1>
          {helpText.map((item) => {
            return <DisclosureComponent title={item.title}>{item.children}</DisclosureComponent>
          })}
        </div>
        <LineCard />
      </div>
    </div>
  )
}
export default Help
