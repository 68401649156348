import TrueMoney from "../../../assets/images/truemoney-logo.webp";

type TrueWalletProps = {
  getTrueWalletUrl: () => void;
};

const TrueWallet = ({ getTrueWalletUrl }: TrueWalletProps) => {
  return (
    <div
      onClick={getTrueWalletUrl}
      className="flex justify-center items-center bg-primary-white rounded-lg px-6 py-2 w-full lg:max-w-[520px] cursor-pointer"
    >
      <div className="mr-6 text-[#141927] font-semibold text-sm leading-[21px]">
        ฝากเงินด้วย
      </div>
      <img src={TrueMoney} alt="truemoney" />
    </div>
  );
};
export default TrueWallet;
