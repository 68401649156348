export const doNotShowToday = () => {
  const today = new Date();
  const date = `${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`;
  localStorage.setItem("do-not-show-today", date);
};

export const isTodayShowModal = () => {
  const today = new Date();
  const nowDate = `${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`;
  const doNotShowToday = localStorage.getItem("do-not-show-today");
  if (doNotShowToday) {
    if (doNotShowToday === nowDate) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
