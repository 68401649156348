import { ReactNode } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type CarouselProps = {
  children: ReactNode;
  className: string;
};

const Carousel = ({ children, className }: CarouselProps) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlaySpeed: 500,
    autoplay: true,
    arrows: false,
  };
  return (
    <div className={className}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};
export default Carousel;
