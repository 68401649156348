import Button from "../button/button.component"
import Modal from "./modal.component"
import liveChat from "../../../assets/images/livechat.png"
import Line from "../../../assets/svg/line-green.svg"
import { useContextAuthManager } from "../../context/auth.context"
import { LINE_OFFICIAL_LINK } from "../../service/env"

type ContactUsModalProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ContactUsModal = ({ isOpen, setIsOpen }: ContactUsModalProps) => {
  const { setIsShowContactUs } = useContextAuthManager()

  return (
    <Modal className="flex flex-col items-center justify-center p-12" isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="my-6 text-3xl font-medium leading-6">เลือกช่องทางติดต่อ</div>
      <div className="flex gap-6">
        <img onClick={() => window.open(LINE_OFFICIAL_LINK, "_blank")} src={Line} alt="line-green-icon" width={120} height={120} />
        <div onClick={() => setIsShowContactUs(true)} className="rounded-2xl overflow-hidden h-fit">
          <img src={liveChat} alt="live-chat-logo" height={100} width={100} />
        </div>
      </div>
      <Button onClick={() => setIsOpen(false)} className="bg-gray-500 text-primary-white px-[15px] py-2 rounded-[100px] text-sm w-full focus:outline-none">
        ปิดหน้าต่าง
      </Button>
    </Modal>
  )
}
export default ContactUsModal
