import { Fragment, useEffect, useState } from "react";
import { Form } from "react-final-form";
import AffiliateXBetAPI from "../../../common/api/affiliate.xbet";
import Button from "../../../common/base-ui/button/button.component";
import Input from "../../../common/base-ui/fields/input";
import Line from "../../../layout/line.component";

type TUser = {
  date: string;
  name: string;
  registerDate: string;
  username: string;
};

const Friends = () => {
  const [users, setUsers] = useState<TUser[]>([]);
  const [usersTemp, setUsersTemp] = useState<TUser[]>([]);
  useEffect(() => {
    const affiliate = new AffiliateXBetAPI();
    const subscribe = affiliate.friends().subscribe({
      next: (res) => {
        setUsers(res);
        setUsersTemp(res);
      },
      error: (err) => {
        console.error(err);
      },
    });
    return () => {
      subscribe.unsubscribe();
    };
  }, []);
  const onSubmit = (values: any) => {
    setUsers(usersTemp);
    if (values.friendsSearch) {
      setUsers((prev) =>
        prev.filter(
          (data) =>
            data.username.includes(values.friendsSearch) ||
            data.name.includes(values.friendsSearch)
        )
      );
    }
  };
  return (
    <div className="w-full">
      <div className="px-8 md:px-4 pt-2">
        <h1 className="text-white font-bold text-lg mb-4">
          รายการสมาชิกของคุณ
        </h1>
        <h2 className="text-primary-white/50 text-sm">
          จำนวนสมาชิก: {users.length.toLocaleString()}
        </h2>
      </div>
      <section className="pt-4 space-y-3 h-screen ">
        <div className="flex flex-wrap-reverse">
          <div className="mx-auto w-full md:w-2/3 p-4">
            <div className="grid gap-1 grid-cols-3 grid-rows-1 bg-primary-white/5 text-primary-gray text-xs font-bold px-4 py-2 rounded-md">
              <div>ชื่อผู้ใช้</div>
              <div>ชื่อ</div>
              <div>วันที่สมัคร</div>
            </div>
            {users.length > 0 ? (
              users.map((data, index) => (
                <Fragment key={data.username + "-" + index}>
                  <div className="grid gap-1 grid-cols-3 grid-rows-1 justify-between text-primary-white items-center text-sm font-light px-[15px] py-[8px]">
                    <div>{data.username}</div>
                    <div>{data.name}</div>
                    <div className="truncate">{data.registerDate}</div>
                  </div>
                  <Line className="!mb-0" />
                </Fragment>
              ))
            ) : (
              <h1 className="text-sm text-white text-center mt-2">
                ยังไม่มีรายการสมาชิกในขณะนี้
              </h1>
            )}
          </div>
          <div className="mx-auto w-full md:w-1/3 p-4 ">
            <div className="bg-primary-white/5 rounded-md space-y-4 p-4">
              <h1 className="text-white font-bold">ค้นหาสมาชิก</h1>
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Input
                        name="friendsSearch"
                        type="text"
                        placeholder="ค้นหาด้วยชื่อผู้ใช้ หรือว่า ชื่อ..."
                        className="w-full bg-graybg py-1.5 px-3 rounded-md text-sm text-white border-none outline-0 mb-4 bg-white/5"
                      />
                      <Button
                        className="bg-primary-yellow text-primary-black px-[15px] py-[6px] rounded-md text-sm mr-2 w-full"
                        type="submit"
                      >
                        ค้นหา
                      </Button>
                    </form>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Friends;
