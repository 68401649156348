import { ReactComponent as Scb } from "../../../assets/banks/scb.svg";
import { ReactComponent as Kbank } from "../../../assets/banks/kbank.svg";
import { ReactComponent as Ktb } from "../../../assets/banks/ktb.svg";
import { ReactComponent as Bbl } from "../../../assets/banks/bbl.svg";
import { ReactComponent as Bay } from "../../../assets/banks/bay.svg";
import { ReactComponent as Tbank } from "../../../assets/banks/tbank.svg";
import { ReactComponent as Ttb } from "../../../assets/banks/tmb.svg";
import { ReactComponent as Gsb } from "../../../assets/banks/gsb.svg";
import { ReactComponent as Baac } from "../../../assets/banks/baac.svg";
import { ReactComponent as Ibank } from "../../../assets/banks/ibank.svg";
import { ReactComponent as Uob } from "../../../assets/banks/uob.svg";
import { ReactComponent as Cimb } from "../../../assets/banks/cimb.svg";
import { ReactComponent as Citi } from "../../../assets/banks/citi.svg";
import { ReactComponent as Lhb } from "../../../assets/banks/lhb.svg";
// import { ReactComponent as Owl } from "../../../assets/banks/owl.svg";
import { ReactComponent as Kk } from "../../../assets/banks/kk.svg";
import { ReactComponent as Ghb } from "../../../assets/banks/ghb.svg";

export const enum ICON_NAME {
  scb = "scb",
  kbank = "kbank",
  ktb = "ktb",
  bbl = "bbl",
  bay = "bay",
  tbank = "tbank",
  tmb = "tmb",
  gsb = "gsb",
  baac = "baac",
  ibank = "ibank",
  uob = "uob",
  cimb = "cimb",
  citi = "citi",
  lhb = "lhb",
  // owl = "owl",
  kk = "kk",
  ghb = "ghb",
}

type IProps = {
  name: ICON_NAME;
};

const Icon = (props: IProps) => {
  const { name } = props;
  const icon = {
    scb: <Scb {...props} />,
    kbank: <Kbank {...props} />,
    ktb: <Ktb {...props} />,
    bbl: <Bbl {...props} />,
    bay: <Bay {...props} />,
    tbank: <Tbank {...props} />,
    tmb: <Ttb {...props} />,
    gsb: <Gsb {...props} />,
    baac: <Baac {...props} />,
    ibank: <Ibank {...props} />,
    uob: <Uob {...props} />,
    cimb: <Cimb {...props} />,
    citi: <Citi {...props} />,
    lhb: <Lhb {...props} />,
    // owl: <Owl {...props} />,
    kk: <Kk {...props} />,
    ghb: <Ghb {...props} />,
  };

  return icon[name];
};

export default Icon;
