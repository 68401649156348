import { APP_NAME, CMS_API } from "./../service/env";
import { map } from "rxjs";
import BaseRxios from "../service/rxios";
import { TPromotionDetail } from "../types/promotion.type";

class PromotionAPI {
  http: BaseRxios;

  constructor() {
    this.http = new BaseRxios({
      baseURL: CMS_API,
    });
  }
  getPromotions() {
    return this.http.get(`/wp-json/jet-cct/promotions`).pipe(
      map((response: any) => {
        return response
          .filter(
            (item: any) =>
              item.used_site[APP_NAME.toLowerCase()] === "true" &&
              item.cct_status === "publish"
          )
          .map((item: any) => {
            return {
              id: item._ID,
              title: item.title,
              imgUrl: item.cover_images,
            };
          });
      })
    );
  }

  getPromotionDetail(id: string) {
    return this.http.get(`/wp-json/jet-cct/promotions/${id}`).pipe(
      map((response: any) => {
        if (
          response.used_site[APP_NAME.toLowerCase()] === "true" &&
          response.cct_status === "publish"
        ) {
          return {
            id: response._ID,
            title: response.title,
            content: response.description,
            imgUrl: response.cover_images,
          };
        }
        return {} as TPromotionDetail;
      })
    );
  }
}

export default PromotionAPI;
