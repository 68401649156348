import { css, cx } from "@emotion/css";
import { ReactComponent as Star } from "../../../assets/svg/star.svg";

type FavoriteButtonProps = {
  onClick: () => void;
  isFavorite?: boolean;
};

const FavoriteButton = ({
  onClick,
  isFavorite = false,
}: FavoriteButtonProps) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        "absolute right-1 top-1 rounded-2xl bg-[#343a40] p-[2px] cursor-pointer",
        css`
          svg {
            path {
              fill: ${isFavorite ? "#ffc107" : "#62676d"};
            }
          }
        `
      )}
    >
      <Star />
    </div>
  );
};
export default FavoriteButton;
