import { cx } from "@emotion/css"
import { useEffect, useMemo, useState } from "react"
import { Form } from "react-final-form"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import AnnouncementAPI from "../../common/api/announcement.api"
import LineAPI from "../../common/api/line.api"
import Button from "../../common/base-ui/button/button.component"
import Input from "../../common/base-ui/fields/input"
import AnnouncementModal from "../../common/base-ui/modal/announcement-modal.component"
import { ROUTE } from "../../common/constant/routes"
import { useContextAuthManager } from "../../common/context/auth.context"
import useLogo from "../../common/hooks/useLogo.hooks"
import { APP_NAME } from "../../common/service/env"
import { setRefreshToken, setToken } from "../../common/service/local-storage"
import { AnnounceModal } from "../../common/types/modal.type"
import { isTodayShowModal } from "../../utils/show-promotion-modal"
import { AGENT } from "../../utils/cross-agent"

const SignUpConnectUser = () => {
  const appNameCondition = APP_NAME === AGENT.shiba168 ? "168shiba" : APP_NAME
  const navigate = useNavigate()
  const { desktopLogo } = useLogo()
  const { setIsLogIn, lineUserId, getUserData } = useContextAuthManager()

  const lineAPI = useMemo(() => {
    return new LineAPI()
  }, [])

  const [isLoading, setLoading] = useState(false)

  const [isAnnounceModalOpen, setIsAnnounceModalOpen] = useState(false)
  const [announce, setAnnounce] = useState({} as AnnounceModal)

  useEffect(() => {
    const isShowModalToday = isTodayShowModal()
    if (isShowModalToday) {
      const announce = new AnnouncementAPI()
      announce.getRegisterAnnounce().subscribe({
        next: (res) => {
          if (res.length > 0) {
            setAnnounce(res[0])
            setIsAnnounceModalOpen(true)
          }
        },
        error: (err) => {
          console.error(err)
        },
      })
    }
  }, [])

  const onSubmit = async (values: any) => {
    setLoading(true)
    const payload = {
      username: values.username,
      password: values.password,
      lineUserId: lineUserId,
    }
    lineAPI.migrateLine(payload).subscribe({
      next: (res) => {
        setToken(res.token)
        setRefreshToken(res.refreshToken)
        toast.success("เข้าสู่ระบบสำเร็จ")
        setIsLogIn(true)
        getUserData()
        setLoading(false)
        navigate(ROUTE.DASHBOARD)
      },
      error: (err) => {
        console.error(err)
        setLoading(false)
        if (err.code === 5000) {
          toast.error("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง")
        } else {
          toast.error(err.message)
        }
      },
    })
  }
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="max-w-[420px] rounded-[17px] p-4 md:bg-black/5 md:border md:border-white/[0.15] md:border-solid">
        <div className="mb-4 max-w-[205px]">
          <img src={desktopLogo} alt={`${APP_NAME}-logo-connect-user`} height="58px" width="auto" />
        </div>
        <div className="text-lg font-bold leading-7 mb-4">กรุณาเข้าสู่ระบบเพื่อเชื่อมต่อ LINE ครั้งแรก</div>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <Input
                    label="ชื่อผู้ใช้"
                    type="text"
                    name="username"
                    placeholder="ชื่อผู้ใช้"
                    className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                    required
                  />
                </div>
                <div>
                  <Input
                    label="รหัสผ่าน"
                    type="password"
                    name="password"
                    placeholder="รหัสผ่าน"
                    className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                    required
                  />
                </div>
                <Button
                  className={cx(isLoading ? "bg-gray-500" : "bg-primary-yellow", "text-primary-black px-[15px] py-2 rounded-[100px] text-sm mr-2 w-full mb-4")}
                  onClick={() => form.change("type", "login")}
                  type="submit">
                  เข้าสู่ระบบ
                </Button>
                <div className="flex justify-center mb-4">หรือ</div>
                <Button
                  onClick={() => navigate(ROUTE.LINE_MIGRATE)}
                  disabled={isLoading}
                  className={cx(
                    isLoading ? "bg-gray-500 border-primary-gray" : "bg-transparent border-primary-yellow",
                    "text-primary-yellow px-[15px] border-primary-yellow border py-2 rounded-[100px] text-sm mr-2 w-full mb-4"
                  )}
                  type="button">
                  สมัครสมาชิก
                </Button>
                <div className="text-center text-sm font-normal leading-[21px] break-words">
                  {appNameCondition}.com ผู้ให้บริการคาสิโนออนไลน์อย่างเป็นทางการ สงวนลิขสิทธ์ © 2021, {appNameCondition}.com
                </div>
              </form>
            )
          }}
        />
      </div>
      <AnnouncementModal isOpen={isAnnounceModalOpen} setIsOpen={setIsAnnounceModalOpen} announce={announce} isOnlyOnce />
    </div>
  )
}
export default SignUpConnectUser
