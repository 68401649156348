import { cx } from "@emotion/css";

type GameTypeProps = {
  className: string;
  src: string;
  title: string;
  alt: string;
  onClick: () => void;
};
const GameType = ({ className, src, title, alt, onClick }: GameTypeProps) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        "cursor-pointer flex rounded-3xl border-solid border px-[15px] py-[5px] bg-primary-white/5",
        className
      )}
    >
      <img className="mr-2" src={src} alt={alt} height="34px" width="34px" />
      <div className="mt-2">{title}</div>
    </div>
  );
};
export default GameType;
