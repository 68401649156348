import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-hot-toast";
import Button from "../../common/base-ui/button/button.component";
import Line from "../../layout/line.component";
import AffMenu from "./component/aff-menu.component";
import AffMember from "../../assets/images/aff-members.png";
import AffHistory from "../../assets/images/aff-history.png";
import DetailList from "./component/detail-list.component";
import { useEffect, useState } from "react";
import UserAPI from "../../common/api/user.api";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../common/constant/routes";
import AffiliateXBetAPI from "../../common/api/affiliate.xbet";
import AlertModal from "../../common/base-ui/modal/alert-modal.component";
import { ErrorModal } from "../../common/types/modal.type";
import { APP_NAME } from "../../common/service/env";
import { css, cx } from "@emotion/css";
import { getToken } from "../../common/service/local-storage";
import RedemptionAPI from "../../common/api/redemption.api";
import copy from "copy-to-clipboard";
import { AGENT } from "../../utils/cross-agent";

type TAffiliate = {
  username: string;
  register: number;
  credit: number;
  click: number;
  commission: {
    casino: number;
    slot: number;
    sport: number;
  };
};

const Affiliate = () => {
  const navigate = useNavigate();
  const [affiliate, setAffiliate] = useState<TAffiliate>({
    username: "",
    register: 0,
    credit: 0,
    click: 0,
    commission: {
      casino: 0,
      slot: 0,
      sport: 0,
    },
  });
  const [updateDate] = useState<string>(
    dayjs(new Date()).format("DD/MM/YYYY - HH:mm")
  );
  const [happyHour, setHappyhour] = useState({
    isShow: false,
    isOpenBtn: false,
    receivePoint: 0,
    message: "",
  });

  const [isOpenIVC, setIsOpenIVC] = useState(false);

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({} as ErrorModal);

  const transferTo = (method: "0" | "1") => {
    const credit = affiliate.credit;
    if (credit <= 0) {
      setErrorMessage({
        message: "จำนวนเงินไม่เพียงพอ",
        code: 0,
      });
      setIsAlertModalOpen(true);
      return;
    }

    const affiliateAPI = new AffiliateXBetAPI();
    affiliateAPI.affWithdraw(method, credit).subscribe({
      next: (res) => {
        let message = "";
        if (res.code === 1001) {
          message = "เครดิตของคุณมีไม่ถึงจำนวนที่ต้องการถอน";
        } else if (res.code === 1002) {
          message = `ขั้นต่ำในการถอนเงินคือ ${res.min} บาท`;
        } else if (res.code === 1004) {
          message = `สามารถถอนได้วันละครั้งเท่านั้น`;
        } else {
          if (res.code === 0) {
            toast.success("โอนเงินสำเร็จ");
            return;
          } else {
            message = res.message;
          }
        }
        setErrorMessage({
          message: message,
          code: res.code,
        });
        setIsAlertModalOpen(true);
      },
      error: (err) => {
        console.error(err);
        setErrorMessage({
          message: err.message,
          code: err.code,
        });
        setIsAlertModalOpen(true);
      },
    });
  };

  const claimHappyHour = () => {
    if (happyHour.message) {
      toast.error(`${happyHour.message}!`, {
        duration: 7000,
      });
      return;
    }
    const happyHourAPI = new RedemptionAPI();
    happyHourAPI.claimHappyHour().subscribe({
      next: (res) => {
        toast.success(`รับ ${res.receivePoint} แต้มสำเร็จ!`, {
          duration: 7000,
        });
        getHappyHour();
      },
      error: (err) => {
        console.error(err);
        setErrorMessage({
          message: err.message,
          code: err.code,
        });
        setIsAlertModalOpen(true);
      },
    });
  };

  const getHappyHour = () => {
    const redemption = new RedemptionAPI();
    redemption.getHappyHour().subscribe({
      next: (res) => {
        setHappyhour({
          isShow: res.isShow,
          isOpenBtn: res.isOpenBtn,
          receivePoint: res.receivePoint,
          message: res.message,
        });
      },
      error: (err) => {
        console.error(err);
      },
    });
  };

  useEffect(() => {
    getHappyHour();
  }, []);

  useEffect(() => {
    const redemption = new RedemptionAPI();
    redemption.getIsOpenIVC().subscribe({
      next: (res) => {
        if (res.isActive) {
          setIsOpenIVC(res.isActive);
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }, []);

  useEffect(() => {
    const user = new UserAPI();
    const subscribe = user.affiliate().subscribe({
      next: (res) => {
        setAffiliate({
          username: res.username,
          register: res.register,
          credit: res.credit,
          click: res.click,
          commission: {
            casino: res.commission.casino,
            slot: res.commission.slot,
            sport: res.commission.sport,
          },
        });
      },
      error: (err) => {
        console.error(err);
        if (err.code === 401) {
          navigate(ROUTE.DASHBOARD);
        }
      },
    });

    return () => {
      subscribe.unsubscribe();
    };
  }, [navigate]);
  const affLink = `https://app.${APP_NAME}.com/?affId=${affiliate.username}&introduceId=6`;

  const copyToClipboard = (link: string) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("คัดลอกลงคลิปบอร์ด!");
      })
      .catch((err) => {
        console.error(err);
        // Problem on android on line liff
        copy(link);
        toast.success("คัดลอกลงคลิปบอร์ด!!");
        // toast.error("เกิดข้อผิดพลาด!");
      });
  };
  return (
    <div className="px-2 text-white pt-7 md:pt-0 sm:justify-center sm:items-center flex flex-col w-full md:w-fit">
      {APP_NAME === AGENT.neko168 ? null : (
        <>
          <h1 className="text-primary-gray text-center text-sm">
            ยอดค่าคอมมิชชั่น
          </h1>
          <h2 className="text-5xl font-semibold text-center my-4">
            {affiliate.credit.toLocaleString()} ฿
          </h2>
          <h2 className="text-center text-sm mb-7">คงเหลือ</h2>
          <div className="flex justify-center gap-x-4">
            <Button
              onClick={() => transferTo("0")} // function to transfer credit to game
              className="bg-primary-yellow text-primary-black px-[15px] py-[6px] rounded-[100px] text-sm mr-2 w-[125px]"
              type="button"
            >
              โอนเข้าเกม
            </Button>
            <Button
              onClick={() => transferTo("1")} // function to transfer credit to game
              className="border border-primary-yellow bg-transparent hover:bg-primary-yellow w-[125px] text-primary-yellow hover:text-primary-black px-[15px] py-[6px] rounded-[100px] text-sm mr-2"
              type="button"
            >
              ถอนเข้าบัญชี
            </Button>
          </div>
        </>
      )}
      <div className="bg-[#21262f] mt-6 py-8 p-5 rounded-lg border-t-4 border-t-primary-yellow w-full md:min-w-[600px]">
        <div className="flex justify-between mb-2 cursor-pointer text-sm">
          <div className="text-primary-gray mr-4">ลิงค์ชวนเพื่อน</div>
          <div
            className="flex cursor-pointer"
            onClick={() => copyToClipboard(affLink)}
          >
            <div className="mr-2 truncate max-w-[150px] md:max-w-full">
              {affLink}
            </div>
            <FontAwesomeIcon icon={faCopy} className="text-primary-yellow" />
          </div>
        </div>
        <div className="flex justify-between text-sm mb-2">
          <h1 className="text-primary-gray">คุณได้รับ / เพื่อนได้รับ</h1>
          <h2>100%/0%</h2>
        </div>
        <div className="bg-[#191e24] p-4 text-sm grid grid-cols-2">
          <div>
            <h2 className="text-primary-gray mb-2">คูณได้รับ</h2>
            <h3 className="text-white">100%</h3>
          </div>
          <div>
            <h2 className="text-primary-gray mb-2">เพื่อนได้รับ</h2>
            <h3 className="text-white">0%</h3>
          </div>
        </div>
      </div>
      {isOpenIVC && (
        <div
          className={cx(
            "w-full mt-4 cursor-pointer",
            css`
              background: url("https://uni-game-centre.sgp1.digitaloceanspaces.com/entrance/christmas-entrance.webp");
              background-position: center right;
              background-size: cover;
              background-repeat: no-repeat;
              padding: 1rem;
              border-radius: 0.5rem;
              border: 2px solid rgba(202, 138, 4, 0.25);
              gap: 0.5rem;
              box-sizing: border-box;
              && > :is(p) {
                margin-bottom: 0;
              }

              && > p:first-child {
                color: #675334;
                font-weight: 700;
                font-size: 1.5rem;
              }

              && > p:last-child {
                color: #334155;
                font-weight: 400;
                font-size: 1rem;
              }

              && > p:last-child > span {
                color: #dc2626;
                font-weight: 700;
              }
            `
          )}
          onClick={() =>
            window.open(
              `https://treasure.bethub.link/?code=${getToken()}`,
              "_blank"
            )
          }
        >
          <p>เปิดกล่องของขวัญ</p>
          <p className="mt-4">เพื่อรับรางวัลสำหรับการชวนเพื่อน</p>
        </div>
      )}
      {happyHour.isShow && (
        <button
          className={cx(
            "button-85 w-full mt-5",
            happyHour.isOpenBtn
              ? "bg-black !text-black"
              : "after:!bg-gray-400 !text-white",
            css`
              -webkit-text-stroke: 0.5px
                ${happyHour.isOpenBtn ? "black" : "white"};
            `
          )}
          onClick={() => claimHappyHour()}
        >
          กดเพื่อรับรางวัลชั่วโมงหรรษา (แต้มปัจจุบัน:{" "}
          {happyHour.receivePoint || 0})
        </button>
      )}
      <div className="w-full">
        <Line className="!my-4" />
        <AffMenu
          onClick={() => navigate(ROUTE.AFFILIATE_FRIENDS)}
          title="สมาชิก"
          desc="ตรวจสอบสมาชิกที่แนะนำได้"
          img={AffMember}
        />
        <AffMenu
          onClick={() => navigate(ROUTE.AFFILIATE_HISTORY)}
          title="ประวัติการถอน"
          desc="ตรวจสอบประวัติการถอนเงินผลตอบแทน"
          img={AffHistory}
        />
        <Line className="!my-4" />
        <DetailList
          title="สมาชิกที่แนะนำได้"
          amount={affiliate.register.toString()}
        />
        <Line className="!my-4" />
        <DetailList
          title="ส่วนแบ่งคาสิโน"
          amount={affiliate.commission.casino + "%"}
        />
        <Line className="!my-4" />
        <DetailList
          title="ส่วนแบ่งกีฬา"
          amount={affiliate.commission.sport + "%"}
        />
        <Line className="!my-4" />
        <DetailList
          title="ส่วนแบ่งสล๊อต"
          amount={affiliate.commission.slot + "%"}
        />
        <Line className="!my-4" />
      </div>
      <div className="text-primary-gray text-xs font-normal">
        อัพเดทล่าสุด: {updateDate} น.
      </div>
      <AlertModal
        isOpen={isAlertModalOpen}
        setIsOpen={setIsAlertModalOpen}
        error={errorMessage}
      />
    </div>
  );
};
export default Affiliate;
