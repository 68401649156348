import dayjs from "dayjs";
import { map } from "rxjs";
import { XBET_API } from "../service/env";
import BaseRxios from "../service/rxios";

class AffiliateXBetAPI {
  http: BaseRxios;

  constructor() {
    this.http = new BaseRxios({
      baseURL: XBET_API,
    });
  }

  affWithdraw(method: "0" | "1", credit: number) {
    return this.http
      .post("/api/aff/withdraw", { to: method, credit: Math.floor(credit) })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  friends() {
    return this.http
      .post("/api/aff/memberList", {
        draw: 0,
        length: 100,
        search: {
          value: "",
        },
        start: 0,
      })
      .pipe(
        map((response: any) => {
          return response.aaData.map((item: any) => {
            return {
              date: item.date,
              name: item.name,
              registerDate: dayjs(item.registerDate).format("DD/MM/YYYY HH:mm"),
              username: item.username,
            };
          });
        })
      );
  }

  transaction() {
    return this.http
      .post("/api/aff/transaction", {
        draw: 1,
        length: 20,
        search: { value: "" },
        start: 0,
      })
      .pipe(
        map((response: any) => {
          return response.aaData.map((item: any) => {
            return {
              date: item.date,
              to: item.w_to,
              credit: item.w_credit,
              status: item.w_status,
            };
          });
        })
      );
  }
}

export default AffiliateXBetAPI;
