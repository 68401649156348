import { cx } from "@emotion/css";
import { ReactNode } from "react";

type GameRowProps = {
  children: ReactNode;
  isMinusMarginTop?: boolean;
};

const GameRow = ({ children, isMinusMarginTop = false }: GameRowProps) => {
  return (
    <div
      className={cx(
        "grid grid-cols-1 md:grid-cols-4 xl:grid-cols-6 gap-3",
        isMinusMarginTop ? "-mt-5" : ""
      )}
    >
      {children}
    </div>
  );
};
export default GameRow;
