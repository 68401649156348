import { css, cx } from "@emotion/css";

type SidebarMenuBGProps = {
  svg: string;
  alt: string;
  text: string;
  count?: number;
  className?: string;
  onClick?: () => void;
};

const SidebarMenuBG = ({
  text,
  svg,
  alt,
  count = 0,
  className,
  onClick,
}: SidebarMenuBGProps) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        "flex justify-between items-center py-[5px] px-[10px] rounded-[100px] cursor-pointer",
        className,
        css`
          background: rgba(0, 0, 0, 0.15);
        `
      )}
    >
      <div className="flex items-center">
        <img src={svg} alt={alt} />
        <div className="ml-3">{text}</div>
      </div>
      {count > 0 && <div>{count}</div>}
    </div>
  );
};
export default SidebarMenuBG;
