import { GAME_TYPE } from "./game-variable";
import Casino from "../../assets/svg/casino.svg";
import Slot from "../../assets/svg/slot.svg";
import Fishing from "../../assets/svg/fishing.svg";
import Sport from "../../assets/svg/sports.svg";
import Lotto from "../../assets/svg/lotto.svg";

export const types = [
  {
    src: Casino,
    title: "เกมคาสิโน",
    name: GAME_TYPE.CASINO,
    alt: "game-type-casino",
  },
  {
    src: Slot,
    name: GAME_TYPE.SLOT,
    title: "เกมสล็อต",
    alt: "game-type-slot",
  },
  {
    src: Fishing,
    name: GAME_TYPE.FISHING,
    title: "เกมยิงปลา",
    alt: "game-type-fishing",
  },
  {
    src: Sport,
    name: GAME_TYPE.SPORT,
    title: "เกมกีฬา",
    alt: "game-type-sport",
  },
  {
    src: Lotto,
    name: GAME_TYPE.LOTTO,
    title: "หวย",
    alt: "game-type-lotto",
  },
  {
    src: "",
    name: GAME_TYPE.HOT,
    title: "เกมยอดฮิตที่น่าสนใจ",
    alt: "",
  },
  {
    src: "",
    name: GAME_TYPE.NEW,
    title: "เกมใหม่ที่น่าสนใจ",
    alt: "",
  },
];
