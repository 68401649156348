import { css, cx } from "@emotion/css";

type SideBarMenuProps = {
  svg: string;
  text: string;
  alt: string;
  className?: string;
  onClick?: () => void;
};

const SideBarMenu = ({
  svg,
  text,
  alt,
  className,
  onClick,
}: SideBarMenuProps) => {
  return (
    <div
      onClick={onClick}
      className={cx("flex mb-4 items-center cursor-pointer", className)}
    >
      <div
        className={cx(
          "w-fit",
          css`
            background: rgba(255, 255, 255, 0.15);
            border-radius: 100px;
            padding: 4px;
          `
        )}
      >
        <img src={svg} alt={alt} width="25px" height="25px" />
      </div>
      <div className="ml-3 text-sm font-normal">{text}</div>
    </div>
  );
};
export default SideBarMenu;
