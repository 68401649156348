import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Observable } from "rxjs";
import { getToken } from "./local-storage";

export type BaseObject = Record<string, unknown>;

export type RxiosConfig = AxiosRequestConfig;

enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

class BaseRxios {
  private httpClient: AxiosInstance;

  constructor(config: { baseURL: string }) {
    this.httpClient = axios.create(config as RxiosConfig);
  }

  private observable(config: RxiosConfig) {
    const token = getToken();

    if (process.env.REACT_APP_SERVER_ENV === "development") {
      if (token) {
        config.headers = {
          Authorization: `xbet ${token}`,
          "x-referer": `https://app.${process.env.REACT_APP_NAME}.com/`,
        };
      } else {
        config.headers = {
          "x-referer": `https://app.${process.env.REACT_APP_NAME}.com/`,
        };
      }
    } else {
      if (token) {
        config.headers = {
          Authorization: `xbet ${token}`,
        };
      }
    }

    const req = this.httpClient.request(config);

    return new Observable((observer) => {
      req
        .then((res) => {
          const { data } = res;
          // if (data.code === 4000) {
          //   observer.error({
          //     code: 4000,
          //     message: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
          //   });
          // }
          if (data.code === 5000) {
            // case username and password is incorrect
            observer.next(data);
          } else {
            observer.next(data);
          }
          observer.complete();
        })
        .catch((err) => {
          if (err.response) {
            observer.error({
              code: err.response.status,
              message: err.response.data.message,
            });
          } else {
            observer.error(err);
          }
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  public get(url?: string, params?: BaseObject, config?: RxiosConfig) {
    return this.observable({
      method: HttpMethod.GET,
      url,
      ...config,
    });
  }

  public post(url: string, payload?: BaseObject, config?: RxiosConfig) {
    return this.observable({
      method: HttpMethod.POST,
      url,
      data: payload,
      ...config,
    });
  }

  public put(url: string, payload?: BaseObject, config?: RxiosConfig) {
    return this.observable({
      method: HttpMethod.PUT,
      url,
      data: payload,
      ...config,
    });
  }

  public patch(url: string, payload?: BaseObject, config?: RxiosConfig) {
    return this.observable({
      method: HttpMethod.PATCH,
      url,
      data: payload,
      ...config,
    });
  }

  public delete(url: string, config?: RxiosConfig) {
    return this.observable({
      method: HttpMethod.DELETE,
      url,
      ...config,
    });
  }
}

export default BaseRxios;
