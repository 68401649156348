import { useState } from "react";
import { Field } from "react-final-form";
import { getBanks } from "../../../utils/banks";

export type SelectProps = {
  name: string;
};

const SelectBank = ({ name }: SelectProps) => {
  const [bankList] = useState(
    getBanks().map((item) => {
      return {
        label: item.bankName,
        value: item.id,
      };
    })
  );
  return (
    <Field name={name}>
      {({ input }) => (
        <>
          <label
            htmlFor={name}
            className="block mb-2 text-sm font-medium text-white"
          >
            ธนาคาร <span className="text-primary-red">*</span>
          </label>
          <select
            {...input}
            id={name}
            onChange={(e) => input.onChange(e.target.value)}
            className="outline-none block text-sm font-semibold text-primary-white rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
          >
            <option value={0}>เลือกธนาคาร</option>
            {bankList.map((item) => {
              return (
                <option key={`select-bank-${item.value}`} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
        </>
      )}
    </Field>
  );
};
export default SelectBank;
