import { map } from "rxjs";
import { APP_NAME, CMS_API } from "../service/env";
import BaseRxios from "../service/rxios";

class BannerAPI {
  http: BaseRxios;

  constructor() {
    this.http = new BaseRxios({
      baseURL: CMS_API,
    });
  }

  getBanner() {
    return this.http.get("/wp-json/jet-cct/dashboard_banner").pipe(
      map((response: any) => {
        return response
          .filter(
            (item: any) =>
              item.used_site[APP_NAME.toLowerCase()] === "true" &&
              item.cct_status === "publish"
          )
          .map((item: any) => {
            return {
              id: item._ID,
              imgUrl: item.image_url,
              redirect: item.endpoint_url,
            };
          });
      })
    );
  }
}
export default BannerAPI;
