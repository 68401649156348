import { css } from "@emotion/css";

export const dotty = css`
  :after {
    display: inline-block;
    animation: dotty steps(1, end) 2s infinite;
    content: "";
    text-align: start;
    width: 12px;
  }

  @keyframes dotty {
    0% {
      content: "";
    }
    25% {
      content: ".";
    }
    50% {
      content: "..";
    }
    75% {
      content: "...";
    }
    100% {
      content: "";
    }
  }
`;
