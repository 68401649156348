import { map } from "rxjs"
import { API_URL, XBET_API } from "../service/env"
import BaseRxios from "../service/rxios"
import { Launching } from "../types/game.type"

class PlayXBetAPI {
  http: BaseRxios
  agentHttp: BaseRxios

  constructor() {
    this.http = new BaseRxios({
      baseURL: XBET_API,
    })
    this.agentHttp = new BaseRxios({
      baseURL: API_URL,
    })
  }
  getGames(path: string, payload: Launching) {
    return this.http.post(path, payload).pipe(
      map((response: any) => {
        return response
      })
    )
  }

  getGamesV2(path: string, payload: Launching) {
    return this.agentHttp.post("/api/v2/games/play", payload).pipe(
      map((response: any) => {
        return response
      })
    )
  }
}

export default PlayXBetAPI
