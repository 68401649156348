export enum GAME_TYPE {
  HOT = "hot",
  NEW = "new",
  SLOT = "slot",
  FISHING = "fishing",
  SPORT = "sport",
  LOTTO = "lotto",
  CASINO = "casino",
  FREE_SPIN = "freespin",
}
