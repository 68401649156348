import { APP_NAME } from "../common/service/env"

export enum AGENT {
  uni168 = "uni168",
  shiba168 = "shiba168",
  neko168 = "neko321",
}

export const defaultUsernameAgent = () => {
  if (APP_NAME === AGENT.uni168) {
    return "exoaxxxxx"
  } else if (APP_NAME === AGENT.shiba168) {
    return "mbtxxxxx"
  } else if (APP_NAME === AGENT.neko168) {
    return "pgbetxxxxx"
  } else {
    return "xxxxxx"
  }
}
