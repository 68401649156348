import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTE } from "../common/constant/routes";
import Layout from "../layout/layout";
import Affiliate from "../pages/affiliate/affiliate.page";
import AffFriends from "../pages/affiliate/friends/friends.page";
import Dashboard from "../pages/dashboard/dashboard.page";
import Deposit from "../pages/deposit/deposit.page";
import Favorite from "../pages/favorite/favorite.page";
import Games from "../pages/games/games.page";
import SubGames from "../pages/games/sub-games.page";
import Help from "../pages/help/help.page";
import History from "../pages/history/history.page";
import AffHistory from "../pages/affiliate/history/history.page";
import Promotion from "../pages/promotion/promotion.page";
import RecentPlay from "../pages/recent-play/recent-play.page";
import Search from "../pages/search/search.page";
import SignIn from "../pages/signin/signin.page";
import Withdraw from "../pages/withdraw/withdraw.page";
import SignUp from "../pages/signup/signup.page";
import SignUpLine from "../pages/signup/signup-line.page";
import SignUpConnectUser from "../pages/signup/signup-connect-user";
import SignUpComplete from "../pages/signup/signup-complete.page";
import Redirect from "../pages/redirect/redirect.page";
import PromotionDetail from "../pages/promotion/promotion-detail.page";
import RedirectSignUp from "../pages/signup/redirect-signup.page";

const Router = () => {
  return <AllRoutes />;
};

const AllRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTE.SIGN_IN} element={<SignIn />} />
      <Route path={ROUTE.SIGN_IN_WITH_REDIRECT} element={<SignIn />} />
      <Route path={ROUTE.SIGN_UP} element={<SignUp />} />
      <Route path={ROUTE.USER_CONNECT} element={<SignUpConnectUser />} />
      <Route path={ROUTE.LINE_MIGRATE} element={<SignUpLine />} />
      <Route path={ROUTE.SIGN_UP_COMPLETE} element={<SignUpComplete />} />
      <Route path={ROUTE.REDIRECT} element={<Redirect />} />
      <Route path={ROUTE.REGISTER} element={<RedirectSignUp />} />
      <Route
        path={ROUTE.DASHBOARD}
        element={
          <Layout noNavbar>
            <Dashboard />
          </Layout>
        }
      />
      <Route
        path={ROUTE.RECENT_PLAY}
        element={
          <Layout>
            <RecentPlay />
          </Layout>
        }
      />
      <Route
        path={ROUTE.FAVORITE}
        element={
          <Layout>
            <Favorite />
          </Layout>
        }
      />
      <Route
        path={ROUTE.PROMOTION}
        element={
          <Layout>
            <Promotion />
          </Layout>
        }
      />
      <Route
        path={ROUTE.PROMOTION_DETAIL}
        element={
          <Layout>
            <PromotionDetail />
          </Layout>
        }
      />
      <Route
        path={ROUTE.HELP}
        element={
          <Layout>
            <Help />
          </Layout>
        }
      />
      <Route
        path={ROUTE.DEPOSIT}
        element={
          <Layout>
            <Deposit />
          </Layout>
        }
      />
      <Route
        path={ROUTE.WITHDRAW}
        element={
          <Layout>
            <Withdraw />
          </Layout>
        }
      />
      <Route
        path={ROUTE.HISTORY}
        element={
          <Layout>
            <History />
          </Layout>
        }
      />
      <Route
        path={ROUTE.SEARCH}
        element={
          <Layout>
            <Search />
          </Layout>
        }
      />
      <Route
        path={ROUTE.AFFILIATE}
        element={
          <Layout>
            <Affiliate />
          </Layout>
        }
      />
      <Route
        path={ROUTE.AFFILIATE_FRIENDS}
        element={
          <Layout>
            <AffFriends />
          </Layout>
        }
      />
      <Route
        path={ROUTE.AFFILIATE_HISTORY}
        element={
          <Layout>
            <AffHistory />
          </Layout>
        }
      />
      <Route
        path={ROUTE.GAMES}
        element={
          <Layout>
            <Games />
          </Layout>
        }
      />
      <Route
        path={ROUTE.SUB_GAMES}
        element={
          <Layout>
            <SubGames />
          </Layout>
        }
      />
      <Route path={"/"} element={<Navigate to={ROUTE.DASHBOARD} replace />} />
      <Route path={"*"} element={<Navigate to={ROUTE.DASHBOARD} replace />} />
    </Routes>
  );
};

export default Router;
