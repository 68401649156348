import { Fragment } from "react"
import Casino from "../../../assets/svg/casino.svg"
import Slot from "../../../assets/svg/slot.svg"
import Fishing from "../../../assets/svg/fishing.svg"
import Sports from "../../../assets/svg/sports.svg"
import Lotto from "../../../assets/svg/lotto.svg"
import Affiliate from "../../../assets/svg/affiliate.svg"
import Mission from "../../../assets/svg/mission.svg"
import Line from "../../../assets/svg/line.svg"
import { css, cx } from "@emotion/css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import Button from "../../../common/base-ui/button/button.component"
import Menu from "./menu.component"
import { useNavigate } from "react-router-dom"
import { ROUTE } from "../../../common/constant/routes"
import LensFlare from "../../../assets/svg/lens-flare.svg"
import { gotoRedemption } from "../../../utils/outside-provider"
import useLogo from "../../../common/hooks/useLogo.hooks"

const InfoSection = ({ username, balance, missionNotifyCount, setOpenContactUsModal }: any) => {
  const navigate = useNavigate()
  const { mobileLogo } = useLogo()
  return (
    <Fragment>
      <div className="rounded-[100px] py-[10px] px-[25px] flex items-center bg-black/25 mt-2">
        <img src={mobileLogo} alt="web-logo" height="28px" width="28px" />
        <div className="ml-3">{username}</div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 mb-4 mt-8 lg:mt-0">
        <div className="flex flex-col items-center justify-center font-medium text-[32px] leading-10">
          <div>฿ {balance}</div>
          <div className="flex items-center font-light text-sm leading-5 text-primary-gray mt-2">
            <div>ยอดคงเหลือ</div>
            <FontAwesomeIcon
              className={cx(
                "ml-2",
                css`
                  @keyframes blinker {
                    50% {
                      opacity: 0;
                    }
                  }
                  animation: blinker 1.5s linear infinite;
                `
              )}
              icon={faSyncAlt}
            />
          </div>
          <div className="mt-3">
            <Button
              onClick={() => navigate(ROUTE.DEPOSIT)}
              className="bg-primary-yellow text-primary-black px-[30px] py-[6px] rounded-[10px] text-sm mr-4 z-10 relative"
              type="button">
              ฝากเงิน
            </Button>
            <Button
              onClick={() => navigate(ROUTE.WITHDRAW)}
              className="text-primary-yellow border-primary-yellow border-[1px] px-[30px] py-[6px] rounded-[10px] text-sm z-10 relative"
              type="button">
              ถอนเงิน
            </Button>
          </div>
        </div>
        <div className="flex lg:hidden justify-center -mt-16 -mb-32">
          <img src={LensFlare} alt="lens-flare" className="max-w-full" />
        </div>
        <div className="flex flex-col my-7 mx-7 items-center lg:items-start">
          <div className="flex flex-row sm:gap-12 lg:gap-0 xl:gap-10 mt-12 lg:mt-0">
            <Menu onClick={() => navigate(`${ROUTE.GAMES}?type=casino`)} src={Casino} alt="casino-menu" text="คาสิโน" />
            <Menu onClick={() => navigate(`${ROUTE.GAMES}?type=slot`)} src={Slot} alt="slot-menu" text="สล๊อต" />
            <Menu onClick={() => navigate(`${ROUTE.GAMES}?type=fishing`)} src={Fishing} alt="fishing-menu" text="ยิงปลา" />
            <Menu onClick={() => navigate(`${ROUTE.GAMES}?type=sport`)} src={Sports} alt="sports-menu" text="กีฬา" />
          </div>
          <div className="flex flex-row sm:gap-12 lg:gap-0 xl:gap-10 mt-7">
            <Menu onClick={() => navigate(`${ROUTE.GAMES}?type=lotto`)} src={Lotto} alt="lotto-menu" text="หวย" />
            <Menu onClick={() => gotoRedemption()} src={Affiliate} alt="affiliate-menu" text="ลิงค์รับทรัพย์" />
            <Menu onClick={() => gotoRedemption()} src={Mission} alt="mission-menu" text="ภารกิจ" missionNotifyCount={missionNotifyCount} />
            <Menu onClick={() => setOpenContactUsModal(true)} src={Line} alt="line-menu" text="ติดต่อเรา" isLine />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default InfoSection
