import { map } from "rxjs";
import { APP_NAME, CMS_API } from "../service/env";
import BaseRxios from "../service/rxios";
import dayjs from "dayjs";

class AnnouncementAPI {
  http: BaseRxios;

  constructor() {
    this.http = new BaseRxios({
      baseURL: CMS_API,
    });
  }

  // Announcement on dashboard page
  getMainAnnounce() {
    return this.http.get("/wp-json/jet-cct/announcement").pipe(
      map((response: any) => {
        return response
          .filter((item: any) => {
            const nowDate = dayjs();
            const startDate = dayjs(item.start_date);
            const endDate = item.end_date ? dayjs(item.end_date) : null;
            let isAnnounceTime;
            if (endDate === null) {
              isAnnounceTime = startDate.diff(nowDate) < 0;
            } else {
              isAnnounceTime =
                startDate.diff(nowDate) < 0 && nowDate.diff(endDate) < 0;
            }
            const isAnnounceDashboard =
              item.display_location.main_page === "true" ||
              item.display_location.main_page_with_vip === "true";
            const isProviderOpen =
              item.used_site[APP_NAME.toLowerCase()] === "true" &&
              item.cct_status === "publish";
            return isAnnounceDashboard && isProviderOpen && isAnnounceTime;
          })
          .map((item: any) => {
            const isVIPAnnounce =
              item.display_location.main_page_with_vip === "true";
            return {
              image: item.image,
              isVIP: isVIPAnnounce,
              redirect: item.endpoint_url,
              title: item.title,
              message: item.content,
              buttonText: item.button_text,
            };
          });
      })
    );
  }

  // Announcement on signup page
  getRegisterAnnounce() {
    return this.http.get("/wp-json/jet-cct/announcement").pipe(
      map((response: any) => {
        return response
          .filter((item: any) => {
            const nowDate = dayjs();
            const startDate = dayjs(item.start_date);
            const endDate = item.end_date ? dayjs(item.end_date) : null;
            let isAnnounceTime;
            if (endDate === null) {
              isAnnounceTime = startDate.diff(nowDate) < 0;
            } else {
              isAnnounceTime =
                startDate.diff(nowDate) < 0 && nowDate.diff(endDate) < 0;
            }
            const isAnnounceDashboard =
              response[0].display_location.register_page === "true";
            const isProviderOpen =
              item.used_site[APP_NAME.toLowerCase()] === "true" &&
              item.cct_status === "publish";
            return isAnnounceDashboard && isProviderOpen && isAnnounceTime;
          })
          .map((item: any) => {
            return {
              image: item.image,
              redirect: item.endpoint_url,
              title: item.title,
              message: item.content,
              buttonText: item.button_text,
            };
          });
      })
    );
  }
}
export default AnnouncementAPI;
