import { css, cx } from "@emotion/css";
import { isMobile } from "react-device-detect";
import Button from "../../../common/base-ui/button/button.component";
import Icon, { ICON_NAME } from "../../../common/base-ui/icon/icon.component";
import { APP_NAME } from "../../../common/service/env";

type DepositCardProps = {
  webLogo: string;
  bankLogo?: ICON_NAME;
  bankName?: string;
  bankAccountNumber?: string;
  bankAccountName?: string;
  copyToClipboard: (bankNumber: string) => void;
};

const DepositCard = ({
  webLogo,
  bankLogo,
  bankName,
  bankAccountNumber,
  bankAccountName,
  copyToClipboard,
}: DepositCardProps) => {
  return (
    <div
      className={cx(
        "flex flex-col items-center w-full lg:max-w-[520px] p-6 rounded-2xl bg-center bg-cover bg-no-repeat mb-4",
        css`
          background: linear-gradient(80.52deg, #000000 40%, #21264a 100%);
        `
      )}
    >
      <div className="flex flex-row items-center justify-between w-full mb-4">
        <img
          src={webLogo}
          alt={`${APP_NAME}-logo`}
          height="34px"
          width="34px"
        />
        <div className="px-2 py-[10px] rounded-3xl bg-primary-white/[0.15] font-light text-primary-white text-xs leading-3">
          บัญชีฝากเงิน
        </div>
      </div>
      <div className="flex flex-row items-center justify-between w-full mb-4">
        {bankLogo && !isMobile && (
          <div className="w-[64px] h-[64px] mr-8 flex items-center">
            <Icon name={bankLogo} />
          </div>
        )}
        <div className="w-full">
          <div className="border-b border-solid border-b-primary-white/[0.15] flex justify-between w-full mb-3">
            <div className="text-primary-white/50">ธนาคาร</div>
            <div>{bankName}</div>
          </div>
          <div className="border-b border-solid border-b-primary-white/[0.15] flex justify-between w-full">
            <div className="text-primary-white/50">ชื่อบัญชี</div>
            <div>{bankAccountName}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center w-full border border-solid border-primary-white/[0.15] rounded-2xl px-[10px] py-[5px]">
        <div className="text-primary-white/50 font-normal text-sm leading-[21px]">
          เลขบัญชี
        </div>
        <div className="text-lg leading-7 font-normal">{bankAccountNumber}</div>
        <Button
          onClick={() => copyToClipboard(bankAccountNumber || "")}
          type="button"
          className="bg-primary-yellow px-4 py-1 rounded-3xl text-primary-black"
        >
          คัดลอก
        </Button>
      </div>
    </div>
  );
};
export default DepositCard;
