import { map } from "rxjs";
import { REDEMPTION_API } from "../service/env";
import BaseRxios from "../service/rxios";

class RedemptionAPI {
  http: BaseRxios;

  constructor() {
    this.http = new BaseRxios({
      baseURL: REDEMPTION_API,
    });
  }
  getMissionNotify() {
    return this.http.get("/users/banner").pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  // not use
  getActivity() {
    return this.http.get("/users/agent-wheels-deposit").pipe(
      map((response: any) => {
        return {
          isActive: response.is_active,
          image: response.wheel_deposit_url,
          url: response.redirect_url,
        };
      })
    );
  }

  getActivityV2() {
    return this.http.get("/users/agent-wheels-deposit-redirect").pipe(
      map((response: any) => {
        return {
          isActive: response.is_active,
          image: response.wheel_deposit_url,
          redirect: response.redirect_url,
        };
      })
    );
  }

  getIsOpenIVC() {
    return this.http.get("/users/ivc").pipe(
      map((response: any) => {
        return {
          isActive: response.is_ivc_active,
        };
      })
    );
  }

  getIsVIPUser() {
    return this.http.get("/users/announcement-mission-vip").pipe(
      map((response: any) => {
        return {
          isVIP: response.is_active_announcement,
        };
      })
    );
  }

  getHappyHour() {
    return this.http.get("/users/happy-hour").pipe(
      map((response: any) => {
        if (response.error_message === null) {
          let isOpenBtn = false;
          const isExecute = Number(response.happy_hour_config_execute) === 1;
          const isActive = response.is_active;
          let message = "";
          if (isExecute) {
            if (isActive) {
              isOpenBtn = true;
            } else {
              message = "คุณไม่มีแต้มจากการเติมในชั่วโมงหรรษา!";
            }
          } else {
            message = "ไม่สามารถรับแต้มได้ในช่วงนี้";
          }
          return {
            isShow:
              Number(response.happy_hour_config_display) === 1 ? true : false,
            isOpenBtn,
            receivePoint: response.total,
            message,
          };
        } else {
          throw response;
        }
      })
    );
  }

  claimHappyHour() {
    return this.http.post("/users/happy-hour/execute").pipe(
      map((response: any) => {
        return {
          receivePoint: response.result.recieve_point,
        };
      })
    );
  }
}

export default RedemptionAPI;
