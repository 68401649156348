import Modal from "./modal.component"
import { AnnounceModal } from "../../types/modal.type"
import { cx } from "@emotion/css"
import { doNotShowToday } from "../../../utils/show-promotion-modal"
import Button from "../button/button.component"
import DOMPurify from "dompurify"
import { useState } from "react"
import { isOutsideLink } from "../../../utils/outside-provider"
import { useNavigate } from "react-router-dom"

type AnnounceModalProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  announce: AnnounceModal
  isOnlyOnce?: boolean
}

const AnnouncementModal = ({ isOpen, setIsOpen, announce, isOnlyOnce = false }: AnnounceModalProps) => {
  const navigate = useNavigate()
  const [check, setCheck] = useState(false)
  return (
    <Modal
      className={cx("relative flex flex-col items-center justify-center !p-0", announce.image ? "!max-w-2xl" : "!max-w-xl !py-4")}
      overlayClassName="bg-black bg-opacity-80"
      isOpen={isOpen}
      setIsOpen={setIsOpen}>
      {announce.image && (
        <>
          <div className="relative w-full flex justify-center">
            <img src={announce.image} alt="announce-icon" className="w-auto max-w-full h-[450px]" />
            <div
              className={cx("absolute inset-10", announce.redirect ? "cursor-pointer" : "")}
              onClick={() => {
                if (announce.redirect) {
                  if (isOutsideLink(announce.redirect)) {
                    window.open(announce.redirect, "_self")
                  } else {
                    navigate(announce.redirect)
                  }
                }
              }}></div>
          </div>
        </>
      )}
      <div className="my-5 text-center px-5">
        <div className="text-3xl font-medium text-primary-white mb-7">{announce.title}</div>
        <div
          className="text-base text-left px-5"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(announce.message || ""),
          }}></div>
      </div>
      {!isOnlyOnce && (
        <div className="flex items-center mb-4 px-5">
          <input
            id="do-not-show-today"
            type="checkbox"
            onClick={() => setCheck(!check)}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded outline-none cursor-pointer"
          />
          <label htmlFor="do-not-show-today" className="ml-2 text-sm font-medium text-gray-300 cursor-pointer">
            ไม่แสดงอีกในวันนี้
          </label>
        </div>
      )}
      {announce.redirect && (
        <Button
          className="text-primary-black px-[15px] py-2 mb-4 rounded-[100px] text-sm bg-primary-yellow w-fit outline-none"
          onClick={() => {
            if (check) doNotShowToday()
            if (announce.redirect) {
              if (isOutsideLink(announce.redirect)) {
                window.open(announce.redirect, "_self")
              } else {
                navigate(announce.redirect)
              }
            }
            setIsOpen(false)
          }}>
          {announce.buttonText || "เข้าสู่หน้าโปรโมชั่น"}
        </Button>
      )}
      <div
        onClick={() => {
          if (check) doNotShowToday()
          setIsOpen(false)
        }}
        className="underline text-primary-white/50 text-center mb-4 cursor-pointer">
        ปิดหน้าต่างนี้
      </div>
    </Modal>
  )
}
export default AnnouncementModal
