import { Fragment, useEffect, useState } from "react";
import AffiliateXBetAPI from "../../../common/api/affiliate.xbet";
import Line from "../../../layout/line.component";

type TTransaction = {
  date: string;
  to: string;
  credit: number;
  status: string;
};

const History = () => {
  const [transactions, setTransactions] = useState<TTransaction[]>([]);
  useEffect(() => {
    const affiliate = new AffiliateXBetAPI();
    const subscribe = affiliate.transaction().subscribe({
      next: (res) => {
        setTransactions(res);
      },
      error: (err) => {
        console.error(err);
      },
    });
    return () => {
      subscribe.unsubscribe();
    };
  }, []);
  return (
    <div className="w-full">
      <div className="px-8 md:px-4 pt-2">
        <h1 className="text-primary-white font-bold text-lg mb-4">
          ประวัติธุรกรรมลิงค์รับทรัพย์
        </h1>
        <h2 className="text-primary-white/50 text-sm">
          จำนวนครั้ง: {transactions.length}
        </h2>
      </div>
      <section className="pt-4 space-y-3 h-screen ">
        <div className="flex flex-wrap-reverse">
          <div className="mx-auto w-full p-4">
            <div className="grid gap-1 grid-cols-4 grid-rows-1 bg-primary-white/5 text-primary-gray text-xs font-bold px-4 py-2 rounded-md">
              <div>วันที่ทำรายการ</div>
              <div>จำนวนเงิน</div>
              <div>ไปที่</div>
              <div>สถานะ</div>
            </div>
            {transactions.length > 0 ? (
              transactions.map((data, index) => (
                <Fragment key={"aff-transaction-" + index}>
                  <div className="grid gap-1 grid-cols-4 grid-rows-1 justify-between text-primary-white items-center text-sm font-light p-4">
                    <div>{data.date}</div>
                    <div>{data.credit.toLocaleString()} บาท</div>
                    <div className="capitalize">{data.to}</div>
                    <div
                      dangerouslySetInnerHTML={{ __html: data.status }}
                    ></div>
                  </div>
                  <Line className="!mb-0" />
                </Fragment>
              ))
            ) : (
              <h1 className="text-sm text-white text-center mt-2">
                ยังไม่มีรายการสมาชิกในขณะนี้
              </h1>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
export default History;
