import { ReactNode } from "react";
import Line from "../../../layout/line.component";

type TitleProps = {
  title: string;
  children: ReactNode;
  onClick: () => void;
};

const Title = ({ children, title, onClick }: TitleProps) => {
  return (
    <div className="pt-2 mb-4">
      <div className="flex justify-between mb-4">
        <div className="font-bold text-base leading-6">{title}</div>
        <div
          onClick={onClick}
          className="text-primary-yellow underline cursor-pointer"
        >
          ดูทั้งหมด
        </div>
      </div>
      <Line />
      {children}
    </div>
  );
};
export default Title;
