import { Fragment, ReactNode } from "react";

type ButtonProps = {
  children: ReactNode;
  type?: "submit" | "button";
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
};

const Button = ({
  children,
  type = "button",
  className,
  disabled = false,
  onClick,
}: ButtonProps) => {
  return (
    <Fragment>
      <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        className={className}
      >
        {children}
      </button>
    </Fragment>
  );
};

export default Button;
