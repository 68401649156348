import { cx } from "@emotion/css"
import { faWallet } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Form } from "react-final-form"
import Button from "../../../common/base-ui/button/button.component"
import Input from "../../../common/base-ui/fields/input"

const WithdrawForm = ({ balance, onSubmit, error, isLoading }: any) => {
  return (
    <div className="p-[14px] rounded-2xl w-full lg:max-w-[520px] flex flex-col justify-between border border-solid border-primary-white/5 bg-primary-black/[0.15]">
      <div className="flex justify-between mb-2">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faWallet} className="text-primary-yellow mr-2" />
          <div>ถอนเงินขั้นต่ำ 300 บาท</div>
        </div>
        <div className="font-normal text-sm text-primary-white/50">คงเหลือ: {balance} ฿</div>
      </div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Input
                name="amount"
                type="text"
                className="w-full text-center rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                placeholder="กรอกจำนวนเงิน"
                maxlength={6}
              />
              {error && <div className="text-center mt-2 text-primary-red">{error}</div>}

              <Button
                className={cx(isLoading ? "bg-gray-500" : "bg-primary-red", "text-primary-white px-[15px] py-2 rounded-[100px] text-sm mr-2 w-full mt-4")}
                type="submit">
                ยืนยันถอนเงิน
              </Button>
            </form>
          )
        }}
      />
    </div>
  )
}
export default WithdrawForm
