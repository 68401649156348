import { css, cx } from "@emotion/css";
import { APP_NAME } from "../../../common/service/env";

const TryToPlay = () => {
  return (
    <>
      <div className="text-base font-bold text-primary-white">
        ทดลองฟรี ก่อนสมัครสมาชิก
      </div>
      <div className="text-xs font-light text-primary-white/50">
        เล่นเอาเพลินเฉย ๆ ก็ได้ อยากได้ของจริงเมื่อไหร่ค่อยสมัครสมาชิก
      </div>
      <div
        className={cx(
          "grid grid-flow-col overflow-x-auto hide-scroll-bar gap-3",
          css`
            grid-template-rows: 1fr;
            /* grid-template-columns: repeat(3, minmax(285px, 1fr)); */
          `,
          css`
            .backdrop {
              margin-top: 15px;
              background-position: center center;
              background-size: cover;
              width: 100%;
              align-items: baseline;
              border-radius: 10px;
              margin-right: 8px;
              padding: 20px;
            }
            .title {
              margin-top: 110px;
              font-size: 20px;
              color: #ffc107;
              font-weight: bold;
            }
            .subtitle {
              font-size: 14px;
              color: rgba(255, 255, 255, 1);
            }

            &:last-child {
              margin-right: 0px;
            }
            * {
              text-align: center;
            }
          `
        )}
      >
        <a
          id="casino"
          href={`https://${APP_NAME}.com/เล่นฟรี/ทดลองเล่นคาสิโน-ฟรี/`}
          className={cx(
            "backdrop",
            css`
              background-image: linear-gradient(
                  to bottom,
                  transparent,
                  rgba(0, 0, 0, 0.75)
                ),
                url("https://wallpaperaccess.com/full/1633753.jpg");
            `
          )}
        >
          <div className="title">คาสิโน</div>
          <div className="subtitle">เล่นฟรี</div>
        </a>
        <a
          id="slot"
          href={`https://${APP_NAME}.com/ทดลองเล่นคาสิโน-สล๊อต-ยิ/ทดลองเล่นสล๊อต-ฟรี/`}
          className={cx(
            "backdrop",
            css`
              background-image: linear-gradient(
                  to bottom,
                  transparent,
                  rgba(0, 0, 0, 0.75)
                ),
                url("https://jokerauto.net/wp-content/uploads/2021/05/966545121.jpg");
            `
          )}
        >
          <div className="title">สล๊อต</div>
          <div className="subtitle">เล่นฟรี</div>
        </a>
        <a
          id="fishing"
          href={`https://${APP_NAME}.com/เล่นฟรี/ทดลองเล่นเกมยิงปลา-ฟรี/`}
          className={cx(
            "backdrop",
            css`
              background-image: linear-gradient(
                  to bottom,
                  transparent,
                  rgba(0, 0, 0, 0.75)
                ),
                url("https://pbs.twimg.com/media/EyggkD0VEAE0fld.png:large");
            `
          )}
        >
          <div className="title">ยิงปลา</div>
          <div className="subtitle">เล่นฟรี</div>
        </a>
      </div>
    </>
  );
};
export default TryToPlay;
