import { css, cx } from "@emotion/css";
import { IMAGE_CDN_URL } from "../../constant/cdn";
import GameSM from "./game-sm.component";

type GameLGProps = {
  categoryName: string;
  name: string;
  imgUrl: string;
  onClick: () => void;
};

const GameLG = ({ name, categoryName, imgUrl, onClick }: GameLGProps) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        "flex flex-col cursor-pointer flex-shrink-0 lg:flex-shrink",
        css`
          &&:hover .text-highlight {
            color: #ffc107;
          }
        `
      )}
    >
      <GameSM
        onClick={onClick}
        name={name}
        categoryName={categoryName}
        imgUrl={imgUrl}
      />
      <div className="border border-solid border-primary-white/[0.15] rounded-xl overflow-hidden w-full mt-3">
        <img
          className="w-full max-h-[200px] max-w-[277px] lg:max-w-full object-cover"
          alt={name}
          src={`${IMAGE_CDN_URL + imgUrl}`}
        />
      </div>
    </div>
  );
};
export default GameLG;
