import { useState, useMemo, useEffect, Fragment } from "react";
import GameAPI from "../../common/api/game.api";
import GameMD from "../../common/base-ui/games/game-md.component";
import GameRow from "../../common/base-ui/games/game-row.component";
import GameSM from "../../common/base-ui/games/game-sm.component";
import AlertModal from "../../common/base-ui/modal/alert-modal.component";
import TitleOnly from "../../common/base-ui/title-only.component";
import { useContextAuthManager } from "../../common/context/auth.context";
import { useGame } from "../../common/hooks/useGame.hooks";
import { useMediaSize } from "../../common/hooks/useMedia.hook";
import { IGame } from "../../common/types/game.type";
import { ErrorModal } from "../../common/types/modal.type";
import Line from "../../layout/line.component";

const Favorite = () => {
  const { useMobileMediaQuery } = useMediaSize();
  const { isLogIn } = useContextAuthManager();
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({} as ErrorModal);
  const { play, favorite } = useGame({
    isLogIn,
    setIsAlertModalOpen,
    setErrorMessage,
  });
  const [games, setGames] = useState<IGame[]>([]);
  const gameAPI = useMemo(() => {
    return new GameAPI();
  }, []);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    const subscribe = gameAPI.getFavoriteGames().subscribe({
      next: (res) => {
        setIsInit(true);
        setGames(res);
      },
      error: (err) => {
        setIsInit(true);
        console.error(err);
      },
    });
    return () => {
      subscribe.unsubscribe();
    };
  }, [gameAPI]);

  return (
    <div className="w-full">
      <TitleOnly titleEn="favorite Games" titleTh="เกมโปรด" />
      {games.length === 0 && isInit && (
        <div className="text-center">ยังไม่มีเกมโปรดในตอนนี้</div>
      )}
      {games.length > 0 && (
        <GameRow isMinusMarginTop>
          {games.map((game) => {
            if (useMobileMediaQuery) {
              return (
                <GameMD
                  key={`search-${game.id}`}
                  isFavorite={game.favorite}
                  className="min-h-[150px]"
                  name={game.nameEn}
                  categoryName={game.categoryName}
                  favorite={() => favorite(game.id)}
                  onClick={() => play(game)}
                  imgUrl={game.img}
                />
              );
            } else {
              return (
                <Fragment key={`game-fragment-${game.id}`}>
                  <GameSM
                    onClick={() => play(game)}
                    key={`game-sm-${game.id}`}
                    name={game.nameEn}
                    categoryName={game.categoryName}
                    imgUrl={game.img}
                    isHover
                  />
                  <Line
                    key={`game-line-${game.id}`}
                    className="mb-0 ml-[90px]"
                  />
                </Fragment>
              );
            }
          })}
        </GameRow>
      )}
      <AlertModal
        isOpen={isAlertModalOpen}
        setIsOpen={setIsAlertModalOpen}
        error={errorMessage}
      />
    </div>
  );
};
export default Favorite;
