import { cx } from "@emotion/css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dotty } from "../../common/constant/animate-css";
import { ROUTE } from "../../common/constant/routes";
import useLogo from "../../common/hooks/useLogo.hooks";
import { APP_NAME } from "../../common/service/env";
import { getToken } from "../../common/service/local-storage";

const Redirect = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { desktopLogo } = useLogo();

  const where = params.where?.toLowerCase() || "";
  const token = getToken();

  const [redirectName, setRedirectName] = useState("");

  useEffect(() => {
    const waitForRedirect = (where?: string) => {
      setTimeout(() => {
        if (where) {
          navigate(ROUTE.SIGN_IN_WITH_REDIRECT.replace(":redirect", where));
        } else {
          navigate(ROUTE.DASHBOARD);
        }
      }, 5000);
    };

    let url = "";
    const hostUrl = window.location.origin;
    if (token) {
      if (where === "rdm") {
        setRedirectName("กำลังพาท่านเข้าสู่ Redemption");
        url = `https://redemption.bethub.link?code=${token}&url=${hostUrl}`;
      } else if (where === "rdm-deposit") {
        setRedirectName("กำลังพาท่านเข้าสู่วงล้อเติมเงิน");
        url = `https://redemption.bethub.link?code=${token}&wheel=deposit`;
      } else if (where === "wheel-rdm") {
        setRedirectName("กำลังพาท่านเข้าสู่วงล้อเติมเงิน");
        url = `https://redemption.bethub.link/?code=${token}&url=${hostUrl}&wheel=rdm`;
      } else if (where === "wheel-deposit") {
        setRedirectName("กำลังพาท่านเข้าสู่วงล้อเติมเงิน");
        url = `https://redemption.bethub.link/?code=${token}&url=${hostUrl}&wheel=deposit`;
      } else if (where === "angpao") {
        setRedirectName("กำลังพาท่านเข้าสู่อั่งเปา");
        url = `https://angpao.bethub.link/?code=${token}`;
      } else if (where === "gift") {
        setRedirectName("กำลังพาท่านเข้าสู่กล่องของขวัญ");
        url = `https://gift.bethub.link/?code=${token}&url=${hostUrl}`;
      } else {
        setRedirectName(
          "หน้าที่ร้องขอไม่มีอยู่จริง หรือหมดช่วงกิจกรรมแล้ว <br /> ระบบจะนำท่านเข้าสู่หน้าหลัก"
        );
        waitForRedirect();
      }
    } else {
      waitForRedirect(where);
    }

    setTimeout(() => {
      window.open(url, "_self");
    }, 2000);
  }, [token, where, navigate]);

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="max-w-[420px] rounded-[17px] p-4 flex flex-col justify-center items-center">
        <div className="mb-5 max-w-[205px]">
          <img
            src={desktopLogo}
            alt={`${APP_NAME}-logo-redirect`}
            height="58px"
            width="auto"
          />
        </div>

        <div
          className={cx("text-lg text-center font-bold leading-7 mb-4", dotty)}
        >
          {redirectName ? (
            <span dangerouslySetInnerHTML={{ __html: redirectName }}></span>
          ) : (
            <>
              ท่านไม่ได้เข้าสู่ระบบ <br />
              ระบบจะพาท่านไปที่หน้าล็อคอิน
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Redirect;
