import Bell from "../assets/images/bell.png";
import Crown from "../assets/images/crown.png";
import Exchange from "../assets/images/exchange.png";
import Roulette from "../assets/images/casino-roulette.png";
import { css, cx } from "@emotion/css";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../common/constant/routes";
import Logout from "../assets/svg/lock.svg";

type FooterProps = {
  logout: () => void;
};

const Footer = ({ logout }: FooterProps) => {
  const navigate = useNavigate();

  return (
    <div className="block lg:hidden fixed bottom-0 bg-primary-black/25 backdrop-blur-[16px] w-full border border-t border-white/5">
      <div className="grid grid-cols-5 justify-evenly py-[10px]">
        <div
          onClick={() => navigate(ROUTE.DASHBOARD)}
          className="flex flex-col justify-center items-center"
        >
          <img src={Crown} alt="footer-bell" className="w-[26px]" />
          <div className="font-normal text-xs leading-[18px]">หน้าหลัก</div>
        </div>
        <div
          onClick={() => navigate(ROUTE.DEPOSIT)}
          className="flex flex-col justify-center items-center"
        >
          <img src={Exchange} alt="footer-bell" className="w-[26px]" />
          <div className="font-normal text-xs leading-[18px]">ฝากถอน</div>
        </div>
        <div
          onClick={() => navigate(ROUTE.GAMES)}
          className="flex flex-col justify-center items-center"
        >
          <div
            className={cx(css`
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 15px solid #6c757d;

              position: absolute;
              z-index: 999;
              transform: rotate(180deg);
              margin-left: auto;
              margin-right: auto;
              left: 0;
              right: 0;
              bottom: 15px;
              text-align: center;
              border-radius: 30px;
            `)}
          />
          <div
            className={cx(css`
              width: 0;
              height: 0;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-top: 10px solid #fef0a0;

              position: absolute;
              z-index: 999;
              transform: rotate(180deg);
              margin-left: auto;
              margin-right: auto;
              left: 0;
              right: 0;
              bottom: 17px;
              text-align: center;
              border-radius: 30px;
            `)}
          />
          <img
            className={cx(
              "w-[64px] absolute bottom-4",
              css`
                && {
                  animation: rotation 8s infinite linear;
                }

                @keyframes rotation {
                  from {
                    transform: rotate(0deg);
                  }
                  to {
                    transform: rotate(359deg);
                  }
                }
              `
            )}
            src={Roulette}
            alt="casino-roulette"
          ></img>
        </div>
        <div
          onClick={() => navigate(ROUTE.PROMOTION)}
          className="flex flex-col justify-center items-center"
        >
          <img src={Bell} alt="footer-bell" className="w-[26px]" />
          <div className="font-normal text-xs leading-[18px]">โปรโมชั่น</div>
        </div>
        <div
          onClick={() => logout()}
          className="flex flex-col justify-center items-center"
        >
          <img src={Logout} alt="footer-bell" className="w-[26px]" />
          <div className="font-normal text-xs leading-[18px]">ออกจากระบบ</div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
