import { map } from "rxjs";
import { API_URL } from "../service/env";
import BaseRxios from "../service/rxios";
import { RegisterWithLine } from "../types/user.type";

type TMigrate = {
  lineUserId: string;
  username: string;
  password: string;
};

class LineAPI {
  http: BaseRxios;

  constructor() {
    this.http = new BaseRxios({
      baseURL: API_URL,
    });
  }

  lineLogin(lineUserId: string) {
    return this.http.get("/api/v2/users/login/" + lineUserId).pipe(
      map((response: any) => {
        if (response.code !== 5000) {
          return {
            token: response.access_token,
            refreshToken: response.refresh_token,
            username: response.result.username,
          };
        } else {
          throw response;
        }
      })
    );
  }

  migrateLine({ lineUserId, username, password }: TMigrate) {
    return this.http
      .post("/api/v2/users/login/" + lineUserId, { username, password })
      .pipe(
        map((response: any) => {
          if (response.code !== 5000) {
            return {
              token: response.access_token,
              refreshToken: response.refresh_token,
              username: response.result.username,
            };
          } else {
            throw response;
          }
        })
      );
  }

  registerWithLine(payload: RegisterWithLine) {
    return this.http.post("/api/v2/register", payload).pipe(
      map((response: any) => {
        if (response.code === 0) {
          return {
            username: response.data.username,
            password: response.data.password,
          };
        } else {
          throw response;
        }
      })
    );
  }
}
export default LineAPI;
