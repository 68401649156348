import { GAME_TYPE } from "../../constant/game-variable";

type GameTagProps = {
  type?: GAME_TYPE;
};
const GameTag = ({ type }: GameTagProps) => {
  const textType = (type?: GAME_TYPE) => {
    if (type === GAME_TYPE.HOT) {
      return "เกมฮิต";
    } else if (type === GAME_TYPE.FREE_SPIN) {
      return "ฟรีสปิน";
    } else if (type === GAME_TYPE.NEW) {
      return "เกมใหม่";
    } else {
      return "";
    }
  };
  return (
    <div className="absolute left-1 top-1 rounded-2xl bg-primary-black/50 px-[10px] py-[2px] cursor-pointer font-normal text-[10px] leading[15px]">
      {textType(type)}
    </div>
  );
};
export default GameTag;
